import MainLayout from "@/layouts/MainLayout.vue";

const routes = [
  {
    path: "/receivables",
    name: "Receivables",
    components: {
      default: () => import("@/pages/receivables/index.vue"),
      MainLayout,
    },
    meta: { middleware: ["auth", "intelligence"] },
  },
];

export default routes;
