import api from "./Api";

export default {
  // create a new po
  createNewPO(organization_hypnID, payload) {
    return api().post("/pos/" + organization_hypnID, payload, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  // update a single po
  updatePO(organization_hypnID, poHypnId, payload) {
    return api().put("/pos/" + organization_hypnID + "/" + poHypnId, payload, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  // get a single po
  getPO(organization_hypnID, poHypnId) {
    return api().get("/pos/" + organization_hypnID + "/" + poHypnId, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  // get all po
  getAllPOs(organization_hypnID) {
    return api().get(
      // "/po/" + organization_hypnID + "?_start=" + start + "&_limit=" + limit,
      "/pos/" + organization_hypnID,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    );
  },

  getDocuments(orgId) {
    return api().get(`/documents/${orgId}`, {
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },

  addDocument(orgId, data) {
    return api().post(`/documents/${orgId}`, data, {
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },

  mergeDocument(orgId, data) {
    return api().post(`/documents/${orgId}/merge`, data, {
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },

  // tigger download of a po pdf
  downloadPOPDF(organization_hypnID) {
    return api().post("/pos/" + organization_hypnID + "/send", null, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },
};
