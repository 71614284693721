import axios from "axios";
//import store from '@/store'
export default () => {
  const api = axios.create({
    baseURL: process.env.VUE_APP_BASEURL,
  });

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(error.response, null, 2));
      }

      if (error.response.status === 401) {
        //this is for unauthorized request, you can choose to redirect the user to login page
        //  store.dispatch('auth/LogoutUser')
        localStorage.removeItem("token");
        localStorage.removeItem("userId");

        // only redirect if we are not on the login page
        const path = window.location.href.split("/").pop();
        if (path !== "login") {
          window.location.href = "/login";
        }
      }

      if (error.response.status === 403) {
        // console.log(error.response.status);
        return Promise.reject(error.response.data);
      }

      return Promise.reject({
        msg: error.response.data.message || "An error occurred",
        status: error.response.data.statusCode,
      });
    }
  );

  return api;
};
