export default (value, format = "2-digit") => {
  const date = new Date(value);

  if (format === "full") {
    // Format the date as "Month Day, Year" using the en-US locale
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  } else {
    // Default format: "2-digit" format for month, day, and year
    return date.toLocaleDateString("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });
  }
};
