import payables from "../../api/payables";

const state = {
  payables: null,
  allPayables: null,
  singlePayable: null,
};
const getters = {
  getPayables: (state) => state.payables,
  getAllPayables: (state) => state.allPayables,

  // GET payables with status paid..
  getPaidPayables: (state) => {
    if (state.allPayables) {
      return state.allPayables.filter((payable) => payable.status === "paid");
    }
  },

  // get an array of all the unpaid payables
  getPayablesTranxArray: (state) => {
    if (state.allPayables) {
      let tranxArr = state.allPayables
        .filter((payable) => payable.status === "unpaid")
        .map((payable) => {
          return {
            vendor: payable.counterparty.vendorname,
            id: payable.reference,
            ref: payable.reference,
          };
        });

      return tranxArr;
    }
  },

  // getter for a single payable
  getSinglePayable: (state) => state.singlePayable,
};
const mutations = {
  SET_PAYABLE(state, data) {
    state.payables = data;
    console.log(JSON.stringify(data, null, 2));
  },
  SET_ALL_PAYABLES(state, data) {
    state.allPayables = data;
    if (process.env.NODE_ENV === "development") {
      console.log(JSON.stringify(data, null, 2));
    }
  },
  SET_SINGLE_PAYABLE(state, data) {
    state.singlePayable = data;
    if (process.env.NODE_ENV === "development") {
      console.log(JSON.stringify(data, null, 2));
    }
  },
};
const actions = {
  async addToPayables({ commit }, payload) {
    try {
      const response = await payables.addInvoiceToPayable(payload);
      commit("SET_PAYABLE", response.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  //get all payables for an organization
  async getAllPayables({ commit }, organization_hypnID) {
    try {
      const response = await payables.getAllPayables(organization_hypnID);
      // sort the response.data.data array so that the latest payables are first in the array

      response.data.data.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });

      commit("SET_ALL_PAYABLES", response.data.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  //get a single payable for an organization
  async getSinglePayable({ commit }, req_data) {
    try {
      const response = await payables.getSinglePayable(
        req_data.organization_hypnID,
        req_data.payable_id
      );
      commit("SET_SINGLE_PAYABLE", response.data.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
