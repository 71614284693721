<template>
  <div class="auth">
    <div class="auth__wrapper">
      <div class="header">
        <a href="/">
          <img :src="require('@/assets/icons/newLogo.svg')" alt="Hyphen" />
        </a>
      </div>
      <div class="content">
        <div class="form">
          <div class="head">
            <span class="title">Create your Organization</span>
            <span class="steps">Step 2 of 2</span>
          </div>

          <span class="desc">
            Hello {{ $route.query.name }}, to get great value from pbot please
            provide information about your company below
          </span>

          <v-text-field
            ref="orgname"
            color="primary"
            placeholder="Company Name"
            background-color="#ffffff"
            outlined
            class="ma-0"
            :disabled="isCreating"
            type="text"
            hide-details="auto"
            v-model="companyDetails.orgname"
            :rules="rules.orgname"
          ></v-text-field>

          <v-select
            ref="country"
            :items="operatingCountries"
            color="primary"
            placeholder="Country of Operation"
            background-color="#ffffff"
            outlined
            class="ma-0"
            :disabled="isCreating"
            type="text"
            hide-details="auto"
            v-model="companyDetails.country"
            :rules="rules.country"
          ></v-select>

          <div class="rows">
            <v-text-field
              ref="state"
              color="primary"
              placeholder="State"
              background-color="#ffffff"
              outlined
              class="ma-0"
              :disabled="isCreating"
              type="text"
              hide-details="auto"
              v-model="companyDetails.state"
              :rules="rules.state"
            ></v-text-field>

            <v-text-field
              ref="city"
              color="primary"
              placeholder="City"
              background-color="#ffffff"
              outlined
              class="ma-0"
              :disabled="isCreating"
              type="text"
              hide-details="auto"
              v-model="companyDetails.city"
              :rules="rules.city"
            ></v-text-field>
          </div>

          <v-btn
            @click="onSubmit"
            block
            color="primary"
            elevation="5"
            height="54px"
            width="88px"
            class="text-capitalize action__button"
            :loading="isCreating"
          >
            Complete
          </v-btn>

          <v-btn
            @click="logout"
            block
            color="primary"
            elevation="0"
            height="54px"
            width="88px"
            class="text-capitalize action__button"
            text
          >
            Logout
          </v-btn>
        </div>
      </div>
    </div>
    <div class="auth__sidebar">
      <div class="cv">
        <h1>
          Automate payment processes <br />
          with <br />
        </h1>

        <span class="msg">
          Hyphen adapts to your internal process to help collect business
          payments faster, and control business payouts better.

          <br />
          <br />
          Start with an account in 5mins
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      snackbar: {
        snackbar: false,
        text: null,
        sclass: null,
        timeout: 1000,
      },
      isCreating: false,
      companyDetails: {
        orgname: "",
        country: "",
        state: "",
        city: "",
      },
      operatingCountries: [
        "Nigeria",
        // "United States of America",
        // "UAE",
        // "Ghana",
      ],

      rules: {
        orgname: [(v) => !!v || "This field is required "],
        country: [(v) => !!v || "This field is required "],
        state: [(v) => !!v || "This field is required "],
        city: [(v) => !!v || "This field is required "],
      },
    };
  },
  mounted() {
    // if (!this.$route.query.id || !this.$route.query.name) {
    //   this.$router.replace("/login");
    // }
  },
  methods: {
    ...mapActions({ showToast: "ui/showToast" }),

    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },

    async onSubmit() {
      Object.keys(this.form).forEach((f) => {
        //throw error to ui if there is any
        this.$refs[f].validate(true);
      });

      if (this.canCompleOrgCreation) {
        const payload = {
          orgname: this.companyDetails.orgname,
          members: [this.$route.query.id],
          company: {
            company_name: this.companyDetails.orgname,
            registration_number: this.companyDetails.cac,
            country: this.companyDetails.country,
            state: this.companyDetails.state,
            city: this.companyDetails.city,
          },
        };

        try {
          this.isCreating = true;
          await this.$store.dispatch(
            "organizations/createOrganization",
            payload
          );

          this.showToast({
            message: "Organization created successfully",
            sclass: "success",
            show: true,
          });

          //   let userId = localStorage.getItem("userId");
          await this.$store
            .dispatch("auth/fetchUserDetails", this.$route.query.id)
            .then(async () => {
              this.showToast({
                message: "fetching organization details",
                sclass: "success",
                show: true,
              });
              await this.$store.dispatch("organizations/getOrganizationToken");
            })
            .then(async () => {
              await this.$store.dispatch(
                "organizations/getOrganizationById",
                this.$store.state.auth.user.organization
              );
            });

          this.showToast({
            message: "Welcome",
            show: true,
            sclass: "success",
          });

          //sucess creating org
          this.$router.replace("/welcome");
        } catch (error) {
          if (process.env.NODE_ENV === "development") {
            console.log(JSON.stringify(error, null, 2));
          }
          this.showToast({
            sclass: "error",
            show: true,
            message: error.msg,
            timeout: 3000,
          });
        } finally {
          this.isCreating = false;
        }
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    form() {
      return {
        orgname: this.companyDetails.orgname,
        country: this.companyDetails.country,
        state: this.companyDetails.state,
        city: this.companyDetails.city,
      };
    },

    canCompleOrgCreation() {
      // loop through rules, if all pass user can create org
      const rules = Object.keys(this.rules);
      return rules
        .map((rule) => {
          return Object.keys(this.rules[rule])
            .map((field, index) => {
              return this.rules[rule][index](this.companyDetails[rule]);
            })
            .every((val) => val == true);
        })
        .every((val) => val == true);
    },
  },
};
</script>

<style lang="scss" scoped>
.auth {
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  //   padding: 2rem 4rem;
  background: #f8f7f4;

  &__wrapper {
    width: 100vw;

    @media screen and (min-width: 768px) {
      width: calc(100vw - 30vw);
    }
  }

  .header {
    height: 60px;
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    width: 100vw;
    background-color: #fff;
    display: flex;
    align-items: center;

    padding: 0px 20px;

    @media screen and (min-width: 1024px) {
      background-color: unset;
      width: unset;
    }
  }

  .content {
    width: 100vw;
    height: calc(100vh - 60px);
    max-width: unset;
    padding: 20px;

    @media screen and (min-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media screen and (min-width: 1024px) {
      width: calc(100vw - 31vw);

      padding: 30px 150px;
    }

    .form {
      width: 100%;
      max-width: 450px;
      display: flex;
      flex-direction: column;
      gap: 30px;

      @media screen and (max-width: 768px) {
        padding-bottom: 30px;
      }

      .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 4px;

        // mobile view
        @media screen and (max-width: 768px) {
          flex-direction: column;
        }

        .title {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 27px;

          letter-spacing: -0.73px;

          color: #596a73;
        }

        .steps {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          /* identical to box height */

          letter-spacing: 0.484848px;

          color: #002338;

          mix-blend-mode: normal;
          opacity: 0.5;
        }
      }

      .desc {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.278409px;
        color: rgba(25, 40, 61, 0.8);
      }

      .rows {
        display: flex;
        flex-direction: row;
        gap: 20px;

        // mobile view
        @media screen and (max-width: 768px) {
          flex-direction: column;
        }
      }

      .forgot {
        display: flex;
        justify-content: flex-end;
        padding-top: 5px;

        a {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0.278409px;
          color: #301f78;
          mix-blend-mode: normal;
          opacity: 0.5;
          text-decoration: none;
          cursor: pointer;
        }
      }

      .no-account {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;

        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.278409px;
          color: #7f919b;
        }

        a {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.278409px;
          color: #5b67ba;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }

  &__sidebar {
    display: none;
    width: 30vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: fixed;
    right: 0;
    top: 0;
    background-color: #fff;

    .cv {
      width: 90%;
      max-width: 400px;
    }

    h1 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 46px;
      color: #19283d;
    }

    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: rgba(25, 40, 61, 0.8);
    }

    @media screen and (min-width: 1024px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
