export default async function ({ store, to, redirect }) {
  const settings = store.state.auth.settingsData;

  const billing = {
    workflows: true,
    forms: true,
    invoices: true,
    procurement: true,
  };

  if (settings && settings.billing) {
    billing.workflows = settings.billing.workflows;
    billing.forms = settings.billing.forms;
    billing.invoices = settings.billing.invoices;
    billing.procurement = settings.billing.procurement;
  }

  const tools =
    store.state.flagsmith.flags.find((flag) => flag.key === "tools")?.value ??
    true;
  const inbox =
    store.state.flagsmith.flags.find((flag) => flag.key === "inbox")?.value ??
    true;
  const procurement =
    store.state.flagsmith.flags.find((flag) => flag.key === "procurement")
      ?.value ?? true;
  const forms =
    store.state.flagsmith.flags.find((flag) => flag.key === "forms")?.value ??
    true;
  const workflows =
    store.state.flagsmith.flags.find((flag) => flag.key === "workflows")
      ?.value ?? true;

  // console.log("workflows", workflows);

  console.log("settings", JSON.stringify(settings, null, 2));

  var roleId = localStorage.getItem("roleId");
  if (!roleId || roleId === "null" || roleId === "undefined") {
    roleId = store?.state?.auth?.user?.role?.id + "";
  }
  localStorage.setItem("roleId", roleId);
  // const hasWorkflowAccess = roleId === "1";

  // if (!tools) {
  //   return redirect("/");
  // } else if (!inbox  && to.path.includes("/inbox")) {
  //   return redirect("/");
  // } else if (!forms && to.path.includes("/form")) {
  //   return redirect("/");
  // } else if (
  //   (!workflows && to.path.includes("/workflow")) ||
  //   (to.path.includes("/workflow") && !hasWorkflowAccess)
  // ) {
  //   return redirect("/");
  // }

  if (!tools) {
    return redirect("/");
  }

  if (to.path.includes("/inbox") && (!billing.invoices || !inbox)) {
    return redirect("/");
  }

  if (
    to.path.includes("/procurement") &&
    (!billing.procurement || !procurement)
  ) {
    return redirect("/");
  }

  if (to.path.includes("/form") && (!billing.forms || !forms)) {
    return redirect("/");
  }

  if (to.path.includes("/workflow") && (!billing.workflows || !workflows)) {
    return redirect("/");
  }
}
