import api from "./Api";
export default {
  getAllVendors(orgId) {
    return api().get(`organizations/${orgId}/vendors`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },

  getVendorById(vendorId) {
    return api().get(`vendors/${vendorId}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },
  updateVendorById(vendorId, payload) {
    return api().put(`vendors/${vendorId}`, payload, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },
  //TODO: Check if these are the correct endpointsfor customers

  getAllCustomers(orgId) {
    return api().get(`organizations/${orgId}/customers`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },

  getCustomerById(customerId) {
    return api().get(`customers/${customerId}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },
  //create a new customer for an organization
  createCustomer(orgId, payload) {
    return api().post(`organizations/${orgId}/customers`, payload, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },
  updateCustomerById(customerId, payload) {
    return api().put(`customers/${customerId}`, payload, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },

  //get all invoices belonging to a customer
  getCustomerInvoices(organizationId, customerId) {
    return api().get(
      `organizations/${organizationId}/customers/${customerId}/invoices`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
  },

  // get customers payments using the customer id
  getCustomerInvoicePayments(customerId) {
    return api().get(`customers/${customerId}/payments`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },
  // get Vendors payments using the Vendor id
  getVendorPayments(vendorId) {
    return api().get(`vendors/${vendorId}/payments`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },

  //get all invoices beloging to a vendor
  getVendorInvoices(organizationId, vendorId) {
    return api().get(
      `organizations/${organizationId}/vendors/${vendorId}/invoices`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
  },
  //get customer outstanding
  getCustomerBalance(organizationHypnId, customerHypnId) {
    return api().get(
      `customers/${organizationHypnId}/balance/${customerHypnId}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
  },

  //send customer invoice
  sendCustomerInvoice(payload) {
    return api().post(`invoices/send`, payload, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },

  // get customer bank details using the organization id and customer id
  getCustomerBankDetails(organizationId, customerId) {
    return api().get(`banks/${organizationId}/wallet/customer/${customerId}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },

  // invite a vendor

  inviteVendor(organizationId, payload) {
    return api().post(
      `organizations/${organizationId}/vendors/invite`,
      payload,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
  },

  // confirm invite vendor token
  confirmInviteVendorToken(token) {
    return api().get(`organizations/confirminvite?otp=${token}`);
  },
  // submit a vendor form using the x-api-key as a header..
  createNewVendor(organizationHypnId, payload) {
    return api().post(
      `organizations/${organizationHypnId}/vendors/submit`,
      payload,
      {
        headers: {
          "x-api-key": sessionStorage.getItem("invitetoken"),
        },
      }
    );
  },

  // send invite mail for portal
  sendPortalInvite(payload) {
    return api().post(`organizations/portalinvite`, payload, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },

  // cancel customer invoice
  cancelInvoice(payload) {
    return api().post(`invoices/cancel`, payload, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },
};
