export default async function ({ store, redirect, to }) {
  const payment =
    store.state.flagsmith.flags.find((flag) => flag.key === "payment")?.value ??
    true;

  const transactions =
    store.state.flagsmith.flags.find((flag) => flag.key === "transactions")
      ?.value ?? true;

  const approvals =
    store.state.flagsmith.flags.find((flag) => flag.key === "approvals")
      ?.value ?? true;

  if (!payment) {
    redirect("/");
  } else if (!transactions && to.path.includes("/transactions")) {
    redirect("/");
  } else if (!approvals && to.path.includes("/approvals")) {
    redirect("/");
  }
}
