export default async function ({ store, redirect, to }) {
  const settings = store.state.auth.settingsData;

  const billing = {
    payables: true,
    receivables: true,
  };

  if (settings && settings.billing) {
    billing.payables = settings.billing.payables;
    billing.receivables = settings.billing.receivables;
  }

  const intelligence =
    store.state.flagsmith.flags.find((flag) => flag.key === "intelligence")
      ?.value ?? true;

  const contacts =
    store.state.flagsmith.flags.find((flag) => flag.key === "contacts")
      ?.value ?? true;

  const payables =
    store.state.flagsmith.flags.find((flag) => flag.key === "payables")
      ?.value ?? true;

  const receivables =
    store.state.flagsmith.flags.find((flag) => flag.key === "receivables")
      ?.value ?? true;

  // if (!intelligence) {
  //   return redirect("/");
  // } else if (!contacts && to.path.includes("/contacts")) {
  //   return redirect("/");
  // } else if (!payables && to.path.includes("/payayble")) {
  //   return redirect("/");
  // } else if (!receivables && to.path.includes("/receivables")) {
  //   return redirect("/");
  // }

  if (!intelligence) {
    return redirect("/");
  }

  if (!contacts && to.path.includes("/contacts")) {
    return redirect("/");
  }

  if (to.path.includes("/payable") && (!billing.payables || !payables)) {
    return redirect("/");
  }

  if (
    to.path.includes("/receivables") &&
    (!billing.receivables || !receivables)
  ) {
    return redirect("/");
  }
}
