import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      orgId: "auth/organizationId",
      orgHypnId: "organizations/getOrganizationHypnID",
      actionsMeta: "workflow/allActions",
      $flags: "flagsmith/flags",
      $orgDetails: "organizations/organObj",
      $summary: "transactions/getTranSummary",
      $appSettings: "auth/getAppSettingsData",
      $appCurrency: "auth/getOrganizationCurrency",
      $appCustomCollectionAcct: "auth/getCustomCollectionAccount",
      $appEnableBanking: "auth/getIsBankingEnabled",
      $appCurrencySymbolAndSigns: "auth/getCurrencySymbolAndSigns",
      walletID: "overview/wallet_id",
      $orgHypnId: "organizations/getOrganizationHypnID",
      $orgId: "auth/organizationId",
    }),

    $hasAccessTo() {
      return {
        // workflows: this.user ? this.user.role.id === 1 : false,
        workflow: true,
        overview: this.$orgDetails ? this.$orgDetails?.token?.kyc : false,
      };
    },
  },
};
