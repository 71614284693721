<template>
  <v-row align="center" class="mx-auto mx-md-14 pt-sm-8 pt-md-10">
    <v-col class="pa-sm-0 d-flex align-center">
      <div class="pa-0">
        <h3 class="text-bold primary--text page__title">
          {{ pageTitle }}
          <span class="transTotal align-center"
            >{{ totalItems }} {{ itemsText }}</span
          >
        </h3>
      </div>

      <v-spacer></v-spacer>

      <slot />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "BaseTitleBar",
  props: {
    pageTitle: {
      type: String,
      required: true,
    },
    itemsText: {
      type: String,
      default: "",
    },
    itemArray: {
      type: Array,
      default: () => [],
    },
    totalItems: {
      type: Number,
    },
  },
  //components: { UploadDialog },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.page__title {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 39px;
}

.v-input .search-field .v-input__slot:before,
.v-input .search-field .v-input__slot:after {
  border: none !important;
  border-color: transparent !important;
}

.transTotal {
  font-family: "Inter" sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 14px;
  color: #7f919b;
  letter-spacing: 0.5px;
}

.v-menu__content {
  box-shadow: none;
}

i.sli-font {
  font-size: 12px;
  display: inline-block;
}

.material-icons {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #19283d;
}

.v-application .elevation-6 {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 3%), 0px 6px 10px 0px rgb(0 0 0 / 3%),
    0px 1px 18px 0px rgb(0 0 0 / 3%) !important;
}

.v-application .elevation-3 {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 3%), 0px 6px 10px 0px rgb(0 0 0 / 3%),
    0px 1px 18px 0px rgb(0 0 0 / 3%) !important;
}

.sub__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 14px;
  letter-spacing: 0.45px;
  color: #7f919b;
}
@media only screen and (min-width: 600px) {
  .page__title {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
  }
  .sub__title {
    font-family: "Inter";
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.45px;
    color: #7f919b;
  }
  .transTotal {
    font-family: "Inter" sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #7f919b;
  }
}
@media only screen and (min-width: 768px) {
  /* For desktop: */
  .page__title {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
  }
  .sub__title {
    font-family: "Inter";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.45px;
    color: #7f919b;
  }
  .transTotal {
    font-family: "Inter" sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #7f919b;
  }
}
</style>
