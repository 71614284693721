<template>
  <v-container class="pa-0 ma-0">
    <v-row align="center" class="pa-0 ma-0">
      <v-col
        class="px-0"
        cols="10"
        offset="1"
        md="6"
        offset-md="3"
        style="min-height: 100vh"
      >
        <v-card color="transparent" elevation="0">
          <p
            class="display-1 font-weight-bold text--secondary"
            :style="{
              paddingLeft: `${$vuetify.breakpoint.smAndUp ? '84px' : '36px'}`,
              paddingTop: `${$vuetify.breakpoint.smAndUp ? '90px' : '34px'}`,
            }"
          >
            Sign in
          </p>

          <div
            :style="{
              paddingLeft: `${$vuetify.breakpoint.smAndUp ? '86px' : '36px'}`,
              paddingRight: `${$vuetify.breakpoint.smAndUp ? '78px' : '34px'}`,
            }"
          >
            <v-form @submit="submitForm" class="ma-auto" ref="form">
              <v-card-text class="pa-0">
                <v-text-field
                  ref="email"
                  color="primary"
                  placeholder="Email"
                  background-color="#ffffff"
                  outlined
                  :disabled="isLoginin"
                  type="email"
                  v-model="loginData.email"
                  :rules="rules.email"
                ></v-text-field>

                <v-text-field
                  ref="password"
                  hide-details="auto"
                  placeholder="Enter Your password"
                  class="pb-0 mb-0 mt-4"
                  background-color="#ffffff"
                  outlined
                  :disabled="isLoginin"
                  v-model="loginData.password"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPass ? 'text' : 'password'"
                  @click:append="showPass = !showPass"
                  :rules="rules.password"
                ></v-text-field>
                <div
                  class="
                    message-details
                    d-flex
                    justify-end
                    my-2
                    forget__password
                  "
                >
                  <router-link
                    to="/forgot-password"
                    class="cursor-pointer border-none text-decoration-none"
                    >Forgot password ?</router-link
                  >
                </div>
              </v-card-text>

              <v-card-actions class="justify-center px-0">
                <v-btn
                  @click="submitForm"
                  block
                  color="primary"
                  elevation="5"
                  height="54px"
                  width="88px"
                  class="text-capitalize action__button"
                  :loading="isLoginin"
                >
                  Continue
                </v-btn>
              </v-card-actions>
            </v-form>
            <div class="chip__wrapper py-2">
              <span class="info__text">Don’t have an account yet?</span>
              <router-link to="sign-up" class="chip__link px-2"
                >Sign Up Here</router-link
              >
            </div>
          </div>
        </v-card>

        <div
          v-if="$vuetify.breakpoint.mdAndUp"
          class="align-center mt-0 text-center text-break"
        >
          <span class="buttom__text">
            We are commited to your privacy. Hyphen is fully encrypted and keeps
            all your sensitive information 100% secure and private</span
          >
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  components: {
    //
  },
  name: "Login",

  mounted() {},

  data() {
    return {
      showPass: false,
      loginData: {
        email: "",
        password: "",
      },
      forgotPasswordMail: "",
      isLoginin: false,
      forgotPassword: "",
      errorMsg: "",
      rules: {
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        password: [(v) => !!v || "This field is required"],
      },
    };
  },

  methods: {
    ...mapActions({ showToast: "ui/showToast" }),

    async submitForm(event) {
      Object.keys(this.form).forEach((f) => {
        this.$refs[f].validate(true);
      });

      if (this.canLogin) {
        event.preventDefault();

        try {
          this.isLoginin = true;
          await this.$store.dispatch("auth/login", {
            identifier: this.loginData.email,
            password: this.loginData.password,
          });

          let userId = localStorage.getItem("userId");
          await this.$store
            .dispatch("auth/fetchUserDetails", userId)
            .then(async () => {
              this.showToast({
                message: "fetching organization details",
                sclass: "success",
                show: true,
              });
              await this.$store.dispatch("organizations/getOrganizationToken");
            })
            .then(async () => {
              await this.$store.dispatch(
                "organizations/getOrganizationById",
                this.$store.state.auth.user.organization
              );
            });

          this.showToast({
            sclass: "success",
            show: true,
            message: "Welcome back",
            timeout: 3000,
          });
          this.$router.push("/welcome");
        } catch (error) {
          this.showToast({
            sclass: "error",
            show: true,
            message: error.msg,
            timeout: 3000,
          });
        } finally {
          this.isLoginin = false;
        }
      }
    },
  },

  computed: {
    form() {
      return {
        email: this.loginData.email,
        password: this.loginData.password,
      };
    },

    canLogin() {
      // loop through rules, if all pass user can try to login
      const rules = Object.keys(this.rules);
      return rules
        .map((rule) => {
          return Object.keys(this.rules[rule])
            .map((field, index) => {
              return this.rules[rule][index](this.loginData[rule]);
            })
            .every((val) => val == true);
        })
        .every((val) => val == true);
    },
  },
};
</script>

<style scoped>
.dskTopTitleSpacing {
  padding-left: 84px;
  padding-top: 90px;
}
.mobileTitleSpacing {
  padding-left: 36px;
  padding-top: 38px;
}

.v-application .elevation-4 {
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 3%), 0px 3px 3px 0px rgb(0 0 0 / 4%),
    0px 3px 5px 0px rgb(0 0 0 / 4%) !important;
}
.forget__password {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.278409px;
  color: #19283d;
  mix-blend-mode: normal;
  opacity: 0.5;
}
.chip__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 29px;
  margin-bottom: 73px;
  background: rgba(127, 145, 155, 0.08);
  border-radius: 16px;
  border: none;
}
.info__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.278409px;
  color: #7f919b;
}
.chip__link {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.278409px;
  color: #5b67ba;
  text-decoration: none;
}

.action__button {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.727273px;
  color: #ffffff;
}

.buttom__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.278409px;
  color: #7f919b;
}
@media only screen and (min-width: 768px) {
  /* For desktop: */
  .info__text {
    font-size: 14px;
    line-height: 17px;
  }
  .chip__link {
    font-size: 14px;
    line-height: 17px;
  }
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .info__text {
    font-size: 12px;
    line-height: 13px;
  }
  .chip__link {
    font-size: 12px;
    line-height: 13px;
  }
}
</style>
