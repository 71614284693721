import user from "./user";
import auth from "./auth";
import guest from "./guest";
import tools from "./flags/tools";
import intelligence from "./flags/intelligence";
import payment from "./flags/payment";

// global middleware - app-wide middleware (runs on every page)
// other middleware can be passed to router inside meta object like so meta:{ middleware: ["middlewae1", "middleware2"]}
export default {
  global: {
    user,
  },
  auth,
  guest,
  tools,
  intelligence,
  payment,
};
