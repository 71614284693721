const state = {
  flags: [],
};

const getters = {
  flags: (state) => state.flags,
};

const mutations = {
  setFlags(state, data) {
    state.flags = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
