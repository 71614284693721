<template>
  <div class="overview-approval">
    <div class="overview-approval__content">
      <div class="title">Your Approvals</div>
      <div class="approvals">
        <div class="head">
          <span>Source</span>

          <span>Action</span>
        </div>

        <div class="items">
          <div class="item" v-for="i in approvals" :key="i">
            <span>{{ i.title }}</span>
            <v-btn
              dark
              outlined
              depressed
              @click="showDetails(i)"
              small
              color="primary"
              class="text-capitalize px-2 my-1 rounded"
              >...</v-btn
            >
          </div>
        </div>
        <div class="see-more" v-if="value && value.length > 5">
          <router-link to="/approvals" class="content">view all...</router-link>
        </div>
      </div>
    </div>
    <ApprovalDetails
      v-model="showApprovalDetails"
      :approval="approval"
      :items="dataItems"
    />
  </div>
</template>

<script>
import ApprovalDetails from "@/components/approval/approvalDetails.vue";

export default {
  props: {
    value: {
      default: () => null,
    },
  },
  components: {
    ApprovalDetails,
  },
  data() {
    return {
      showApprovalDetails: false,
      approval: {},
      dataItems: [],
    };
  },
  computed: {
    approvals() {
      if (!this.value) return [];
      return this.value.slice(0, 5);
    },
  },
  methods: {
    showDetails(approval) {
      this.approval = approval;
      this.dataItems = Object.keys(approval.items).map((key) => ({
        key,
        value: approval.items[key],
      }));
      this.showApprovalDetails = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.overview-approval {
  margin-top: 10px;
  padding: 20px 10px;

  &__content {
    width: 100%;

    .title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: rgba(25, 40, 61, 0.8);
    }

    .approvals {
      padding: 10px;
      max-width: 600px;

      // tab
      @media (min-width: 768px) {
        padding: 20px;
      }

      .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 17px;
          color: #19283d;
        }
      }

      .items {
        .item {
          padding: 5px 0px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      .see-more {
        .content {
          font-weight: 600;
          font-size: 12px;
          text-decoration-line: underline;
          color: #5b67ba;
          padding: 2px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
