import invoices from "../../api/invoices";

const state = {
  //

  allInvoices: [],
  singleInvoice: {},
  singleCustomerInvoice: {},
  newCustomerInvoice: null,
  documents: [],
};

const getters = {
  //get all invoices and order by created_at
  getInvoices(state) {
    if (state.allInvoices.data != null) {
      return state.allInvoices.data.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
    }
  },

  //get pagination data
  getPagination(state) {
    if (state.allInvoices.pagination != null) {
      return state.allInvoices.pagination;
    }
  },
  numOfInvoices(state) {
    if (state.allInvoices != null) {
      return state.allInvoices.pagination
        ? state.allInvoices.pagination.total
        : 0;
    }
  },
  getSingleInvoice(state) {
    return state.singleInvoice;
  },

  checkInvoiceArray(state) {
    if (state.allInvoices.data.length === 0) {
      //Return false if the array is empty
      return false;
    }
    //Otherwise, return true.
    else return true;
  },
  checkNumberOfExceptions(state) {
    if (state.allInvoices.data) {
      let exceptionInvoiceArray = state.allInvoices.data.filter((obj) => {
        return obj.exception == "1";
      });

      if (exceptionInvoiceArray.length === 0) {
        return 0;
      } else return exceptionInvoiceArray.length;
    }
  },
  //get single customer invoice
  getSingleCustomerInvoice(state) {
    return state.singleCustomerInvoice;
  },

  //get new customer invoice
  getNewInvoiceData(state) {
    return state.newCustomerInvoice;
  },
};
const mutations = {
  //
  setInvoices(state, invoices) {
    state.allInvoices = invoices;
  },

  setDocuments(state, invoices) {
    state.documents = invoices;
  },

  setSingleInv(state, invoice) {
    state.singleInvoice = invoice;
  },
  setSingleCustomerInvoice(state, invoice) {
    state.singleCustomerInvoice = invoice;
  },
  setNewCustomerInvoice(state, data) {
    state.newCustomerInvoice = data;
  },
  updateNewCustomerInvoice(state, data) {
    state.newCustomerInvoice = data;
  },
};
const actions = {
  //

  // async FetchInvoicesWtLimit({ commit, rootState }, startValue, limitValue) {
  //     try {
  //         const response = await invoices.getAllWithLimit(
  //             rootState.auth.user.organization.id,
  //             startValue,
  //             limitValue
  //         );
  //         commit("setInvoicesWithLimit", response.data);
  //         //console.log(JSON.stringify(response.data, null, 2));
  //         return response;
  //     } catch (error) {
  //         console.log(JSON.stringify(error, null, 2));
  //         return Promise.reject(error);
  //     }
  // },
  async FetchAllInvoices({ commit, rootState }) {
    try {
      const response = await invoices.getAll(rootState.auth.user.organization);
      commit("setInvoices", response.data);

      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(response, null, 2));
      }
      return response;
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(error, null, 2));
      }
      return Promise.reject(error);
    }
  },
  async fetchDocuments({ commit, rootState }) {
    try {
      const response = await invoices.getDocuments(
        rootState.organizations.fullOrganizationObject?.hypn_id
      );
      commit("setDocuments", response.data.data);
      return response.data.data;
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(error, null, 2));
      }
      return Promise.reject(error);
    }
  },

  async addDocument({ rootState }, data) {
    try {
      const response = await invoices.addDocument(
        rootState.organizations.fullOrganizationObject?.hypn_id,
        data
      );

      return response.data.data;
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(error, null, 2));
      }
      return Promise.reject(error);
    }
  },

  async mergeDocument({ rootState }, data) {
    try {
      const response = await invoices.mergeDocument(
        rootState.organizations.fullOrganizationObject?.hypn_id,
        data
      );

      return response.data.data;
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(error, null, 2));
      }
      return Promise.reject(error);
    }
  },

  async getInvoiceById({ commit }, id) {
    try {
      const response = await invoices.getInvoice(id);
      commit("setSingleInv", response.data);
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(response.data, null, 2));
      }
      return response;
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(error, null, 2));
      }
      return Promise.reject(error);
    }
  },
  async updateInvoiceById({ commit }, data) {
    try {
      const response = await invoices.updateInvoice(data.id, data.payload);
      commit("setSingleInv", response.data);
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(response.data, null, 2));
      }
      return response;
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(error, null, 2));
      }
      return Promise.reject(error);
    }
  },
  async sendInvoicesToWorkFlow(data) {
    try {
      const response = await invoices.sendToWorkflow(data);
      console.log(response.data);
      return response;
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(error, null, 2));
      }
      return Promise.reject(error);
    }
  },

  //create a new customer invoice
  async createCustomerInvoice({ commit }, payload) {
    try {
      const { data } = await invoices.createCustomerInvoice(payload);
      commit("setNewCustomerInvoice", data.data);

      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(data, null, 2));
      }
      return data;
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(error, null, 2));
      }
      return Promise.reject(error);
    }
  },
  //update customer invoice
  async updateCustomerInvoice({ commit }, request) {
    try {
      const { data } = await invoices.updateCustomerInvoice(
        request.id,
        request.payload
      );
      commit("updateNewCustomerInvoice", data.data);

      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(data, null, 2));
      }
      return data;
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(error, null, 2));
      }
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
