import quotes from "../../api/quotes";

const state = {
  allQuotes: [],
  singleQuote: null,
  converted_quote_res: null,
}; //define the state

const getters = {
  getQuotes(state) {
    return state.allQuotes.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
  },
  // get all quotes that has been accepted
  getAcceptedQuotes(state) {
    if (state.allQuotes) {
      return state.allQuotes.filter((quote) => quote.status === "accepted");
    }
  },

  getSingleQuote(state) {
    return state.singleQuote ? state.singleQuote.data : null;
  },
};

const mutations = {
  SET_ALL_QUOTES(state, payload) {
    state.allQuotes = payload;
  },
  SET_SINGLE_QUOTE(state, payload) {
    state.singleQuote = payload;
  },

  SET_CONVERTED_QUOTE_RESPONSE(state, payload) {
    state.converted_quote_res = payload;
  },
};

const actions = {
  // get all quotes
  async getAllQuotes({ commit }, organization_hypnID) {
    try {
      const { data } = await quotes.getAllQuotes(organization_hypnID);
      commit("SET_ALL_QUOTES", data.data.reverse());
      if (process.env.NODE_ENV === "development") {
        // console.log(JSON.stringify(response, null, 2));
        // console.log(response);
      }

      // return a reverse sorted array of quotes
      return data.data;
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(error, null, 2));
      }
      return Promise.reject(error);
    }
  },

  // get a single quote
  async getQuote({ commit }, payload) {
    try {
      const response = await quotes.getQuote(
        payload.organization_hypnID,
        payload.quoteHypnId
      );
      commit("SET_SINGLE_QUOTE", response.data);
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(response, null, 2));
      }
      return response;
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(error, null, 2));
      }
      return Promise.reject(error);
    }
  },

  // create a new quote
  async createNewQuote({ commit }, reqData) {
    try {
      const response = await quotes.createNewQuote(
        reqData.organization_hypnID,
        reqData.payload
      );
      commit("SET_SINGLE_QUOTE", response.data);
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(response, null, 2));
      }
      return response;
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(error, null, 2));
      }
      return Promise.reject(error);
    }
  },

  // update a quote
  async updateQuote({ commit }, reqData) {
    try {
      const response = await quotes.updateQuote(
        reqData.organization_hypnID,
        reqData.quoteHypnId,
        reqData.payload
      );
      commit("SET_SINGLE_QUOTE", response.data);
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(response, null, 2));
      }
      return response;
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(error, null, 2));
      }
      return Promise.reject(error);
    }
  },

  // convert a quote to an invoice
  async convertQuoteToInvoice({ commit }, reqData) {
    try {
      const response = await quotes.convertQuoteToInvoice(
        reqData.organization_hypnID,
        reqData.quoteHypnId
      );
      commit("SET_SINGLE_QUOTE", response.data);
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(response, null, 2));
      }
      return response;
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(error, null, 2));
      }
      return Promise.reject(error);
    }
  },

  // trigger quote download
  async triggerQuoteDownload({ commit }, organization_hypnID) {
    try {
      const response = await quotes.downloadQuotePDF(organization_hypnID);
      commit("SET_SINGLE_QUOTE", response.data);
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(response, null, 2));
      }
      return response;
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(error, null, 2));
      }
      return Promise.reject(error);
    }
  },

  //generate delivery note
  async generateDeliveryNote(_, reqData) {
    try {
      const response = await quotes.generateDeliveryNote(reqData.organization_hypnID, reqData.payload);

      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(response, null, 2));
      }

      return response;
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(error, null, 2));
      }

      return Promise.reject(error);

    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
