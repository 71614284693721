/* This middleware is used ot fetch user details from the database and store it in the store.
 *  if it is not present in the store, it will fetch it from the database and store it in the store.
 */

export default async function ({ store, redirect, to }) {
  let userId = localStorage.getItem("userId");
  if (process.env.NODE_ENV === "development") {
    console.log("userDetails middleware");
    console.log("path", to.path);
  }

  // user details is empty and userId is present in the local storage
  if (store.state.auth.user == null && userId != null) {
    if (process.env.NODE_ENV === "development") {
      console.log("fetching user details...");
    }

    try {
      await store
        .dispatch("auth/fetchUserDetails", userId)
        .then(async () => {
          if (!store?.state?.auth.user?.organization) {
            if (process.env.NODE_ENV === "development") {
              console.log("user does not have an organization...");
            }

            if (!to.path.includes("register/organization")) {
              redirect(
                `/register/organization/?name=${store?.state?.auth?.user?.first_name}&id=${userId}`
              );
              return;
            }
          } else {
            if (store?.state?.auth.user?.organization) {
              const { data } = await store.dispatch(
                "organizations/getOrganizationToken"
              );
              let hypId = data.data.hypn_id;
              await store.dispatch("auth/settingUserApp", hypId);
            }
          }
        })
        .then(async () => {
          if (store?.state?.auth.user?.organization) {
            await store.dispatch(
              "organizations/getOrganizationById",
              store.state.auth.user.organization
            );
          }
        });
    } catch (error) {
      if (
        error.msg !==
        "Resource not found. Confirm you belong to the organization"
      ) {
        redirect("/error");
      }

      if (process.env.NODE_ENV === "development") {
        console.log("error ....>", JSON.stringify(error, null, 2));
      }
    }
  } else {
    // user details is not empty and userId is present in the local storage
    // or user details is empty and userId is not present in the local storage
    if (process.env.NODE_ENV === "development") {
      console.log("user might already exists...");
    }
  }
}
