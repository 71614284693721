<template>
  <div class="card mx-4">
    <div class="contact_row d-flex justify-center align-center">
      {{ contact }}
    </div>
    <div class="d-flex align-center justify-space-around">
      <v-chip
        :color="chipcolor"
        :text-color="textColor"
        class="chip__text"
        x-small
        >{{ directiontag }}</v-chip
      >
      <div>
        <span class="mb-0 text-center row__text" style="">{{ amount }}</span>
      </div>
      <div>
        <span class="mb-0 row__text">{{ account }}</span>
      </div>
    </div>
    <div class="d-flex align-center justify-space-around">
      <div>
        <span class="mb-0 text-right text-capitalize text-truncate row__text">{{
          paymentDate
        }}</span>
      </div>
      <div>
        <span class="mb-0 row__text">{{ transactionRef }}</span>
      </div>
      <!-- <div class="d-flex align-center justify-start">
  
                  <span class="mb-0 row__text" :style="{color: statusIcon}">{{ status }}</span>
              </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "RecentTransactionTableCards",

  props: {
    directiontag: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
    },
    amount: {
      type: [String, Number],
    },

    account: {
      type: String,
    },
    transactionRef: {
      type: String,
    },
    paymentDate: {
      type: String,
    },

    contact: {
      type: String,
    },
    status: {
      type: String,
    },
  },
  computed: {
    statusIcon() {
      if (this.status === "pending") {
        return "#E3AA1C";
      } else if (this.status === "in review") {
        return "#2B99D5";
      } else return "#2BD5AE";
    },
    chipcolor() {
      if (this.directiontag === "credit") {
        return "#EEFCF9";
      } else return "#FDF9EF";
    },
    textColor() {
      if (this.directiontag === "credit") {
        return "#2BD5AE";
      } else return "#F7CA40";
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  // height: 200px;
  display: grid;
  grid-gap: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;

  .contact_row {
    width: 100%;
    font-weight: 600;
  }
}

.row__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #596a73;
}

.chip__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
}
</style>
