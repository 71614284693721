import Vue from "vue";
import VueRouter from "vue-router";
import OnBoarding from "@/layouts/OnboardingPage.vue";
import MainLayout from "@/layouts/MainLayout.vue";

import _middleware from "../middleware";
import store from "@/store";

import auth from "./auth";
import workflow from "./workflow";
import contacts from "./contacts";
import transactions from "./transactions";
import invoices from "./invoices";
import quotes from "./quotes";
import forms from "./forms";
import payables from "./payables";
import receivables from "./receivables";
import approvals from "./approvals";
import Dashboard from "@/pages/overview/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: { middleware: ["auth"] },
  },

  ...auth,
  ...workflow,
  ...contacts,
  ...transactions,
  ...invoices,
  ...quotes,
  ...forms,
  ...payables,
  ...receivables,
  ...approvals,

  {
    path: "/onboarding",
    name: "OnBoarding",
    component: OnBoarding,
  },

  {
    path: "/organizations",
    name: "organizationList",
    components: {
      default: () => import("@/views/organizationList.vue"),
    },
    meta: { middleware: ["auth"] },
  },
  {
    path: "/welcome",
    name: "Welcome",
    components: {
      default: () => import("@/views/navigation-bar/WelcomePage.vue"),
      MainLayout,
    },
    meta: { middleware: ["auth"] },
  },
  {
    path: "/insight",
    name: "Insight",
    components: {
      default: () => import("@/views/navigation-bar/Insight.vue"),
      MainLayout,
    },
    meta: { middleware: ["auth"] },
  },

  {
    path: "/invite-vendor/:id/:token",
    name: "NewVendor",
    components: {
      default: () => import("@/views/inviteVendor.vue"),
    },
  },
  {
    path: "/transaction-receipt/:paymentId",
    name: "transactionReceipt",
    components: {
      default: () => import("@/views/transactionReceipt.vue"),
    },
  },
  {
    path: "/approval",
    name: "Approval",
    components: {
      default: () => import("@/views/approval.vue"),
    },
  },
  {
    path: "/team",
    name: "Teams",
    components: {
      default: () => import("@/pages/team/index.vue"),
      MainLayout,
    },
    meta: { middleware: ["auth"] },
  },
  {
    path: "/settings",
    components: {
      default: () => import("@/pages/settings/index.vue"),
      MainLayout,
    },
    meta: { middleware: ["auth"] },
  },

  // {
  //   path: "/settings/profile",
  //   components: {
  //     default: () => import("@/views/settings/Profile.vue"),
  //     MainLayout,
  //   },
  //   meta: { middleware: ["auth"] },
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  // middleware specified in route config
  const routeMiddlewares = to.meta.middleware;

  // middleware specified in global config
  const globalMiddlewares = _middleware.global
    ? Object.keys(_middleware.global)
    : [];

  // add the two middleware arrays together and create a new array with no duplicate middleware names
  const middlewares = [
    ...new Set(globalMiddlewares.concat(routeMiddlewares || [])),
  ];

  // flatten middleware object list
  const MIDDLEWARE = { ..._middleware, ..._middleware.global };
  delete MIDDLEWARE.global;

  // redirection callback
  const redirect = (route) => {
    if (process.env.NODE_ENV === "development") {
      console.log("redirect to... " + route);
    }
    next(route);
  };

  // if middleware is not defined, just continue to the route
  // if (middlewares && middlewares.length > 0) {
  //   middlewares.forEach(async (m) => {
  //     try {
  //       await MIDDLEWARE[m]({ to, from, store, redirect });
  //     } catch (e) {
  //       if (process.env.NODE_ENV === "development") {
  //         console.log(JSON.stringify(e, null, 2));
  //       }
  //     }
  //   });
  // }

  if (middlewares && middlewares.length > 0) {
    // promise all middleware make sure MIDDLEWARE[m] is a function and it finishes before executing the next middleware then next route

    // filter out middleware that is not a function
    const middlewareFunctions = middlewares.filter(
      (m) => typeof MIDDLEWARE[m] === "function"
    );

    // execute middleware
    Promise.all(
      middlewareFunctions.map((m) =>
        MIDDLEWARE[m]({ to, from, store, redirect })
      )
    )
      .then(() => next())
      .catch((e) => {
        if (process.env.NODE_ENV === "development") {
          console.log(JSON.stringify(e, null, 2));
        }

        redirect("/error");
      });
  } else {
    next();
  }
});

export default router;
