<template>
  <div class="overview-transactions">
    <v-card
      elevation="6"
      flat
      width="100%"
      style="border-bottom: 1px solid rgba(127, 145, 155, 0.3)"
    >
      <v-tabs
        slider-color="primary"
        align-with-title
        v-model="tab"
        slider-size="4"
      >
        <v-tab
          class="mt-2"
          v-for="item in recentTransactionTabs"
          :key="item.tab"
          style="
            font-family: Inter;
            font-style: normal;
            font-weight: 800;
            font-size: 12px;
            line-height: 15px;
            text-transform: uppercase;
          "
          >{{ item.tab }}</v-tab
        >
      </v-tabs>
    </v-card>

    <v-card
      min-height="40px"
      width="100%"
      flat
      class="d-flex align-center py-8 py-md-0 px-3"
      style="background: rgba(127, 145, 155, 0.052607)"
    >
      <div class="d-block d-md-flex align-center" style="width: 100%">
        <div class="d-flex align-center py-2" style="width: 400px">
          <v-select
            @change="handleDateFilter"
            v-model="selectedDateTime"
            :items="dateTimeFilter"
            item-text="text"
            item-value="value"
            placeholder="Date Filter"
            outlined
            background-color="#ffffff"
            hide-details="auto"
            dense
            class="pr-4"
          ></v-select>
          <v-select
            v-model="selectedCurrency"
            :items="currencies"
            placeholder="Date Filter"
            outlined
            background-color="#ffffff"
            hide-details="auto"
            dense
          ></v-select>
        </div>
        <v-spacer></v-spacer>
      </div>
    </v-card>

    <div class="">
      <component
        v-bind:is="recentTransactionTabs[tab].content"
        class="ml-0"
        ref="RecentTransactionPage"
      >
      </component>
    </div>
  </div>
</template>

<script>
import RecentTransactionPage from "@/components/overview/RecentTransactionPage.vue";
export default {
  components: {
    RecentTransactionPage,
  },
  data() {
    return {
      selectedDateTime: 0,
      tab: 0,
      recentTransactionTabs: [
        { tab: "Recent Payments", content: "RecentTransactionPage" },
      ],
      dateTimeFilter: [
        { text: "All time", value: 0 },
        { text: "Last 24 Hours", value: 1 },
        { text: "Last 7 Days", value: 7 },
        { text: "Last 30 Days", value: 30 },
      ],
      selectedCurrency: 1,
      currencies: [
        { text: "All currencies", value: 0 },
        { text: "NGN", value: 1 },
        { text: "USD", value: 2 },
        { text: "EUR", value: 3 },
        { text: "GBP", value: 4 },
      ],
    };
  },

  methods: {
    async handleDateFilter() {
      await this.$refs.RecentTransactionPage.filterByDate(
        this.selectedDateTime
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.overview-transaction {
  &__header {
    padding: 20px;

    @media (min-width: 768px) {
      padding: 15px 40px;
    }

    transform: translateY(9px);

    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      text-transform: uppercase;
      color: rgba(25, 40, 61, 0.8);
      padding: 10px 20px;
      border-bottom: 4px solid #19283d;
    }
  }

  &__hero {
    border-top: 1px solid rgba(127, 145, 155, 0.3);
    background: rgba(127, 145, 155, 0.052607);
    padding: 30px 20px;

    @media (min-width: 768px) {
      padding: 30px 40px;
    }

    .selecters {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 300px;
      gap: 30px;
    }
  }

  &__body {
    padding: 30px 0px;
  }
}

.v-application .elevation-6 {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 3%), 0px 6px 10px 0px rgb(0 0 0 / 3%),
    0px 1px 18px 0px rgb(0 0 0 / 3%) !important;
}
</style>
