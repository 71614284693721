<template>
  <v-container class="pa-0 ma-0">
    <v-row align="center" class="pa-0 ma-0">
      <v-col
        class="px-0"
        cols="10"
        offset="1"
        md="8"
        offset-md="2"
        style="min-height: 100vh"
      >
        <v-card elevation="0" color="transparent" v-if="pageOne">
          <v-row class="pa-0 ma-0 d-flex flex-row justify-center align-center">
            <v-col cols="12" md="8" class="pa-0">
              <p
                class="
                  text-md-h4 text-h5
                  font-weight-bold
                  mt-2
                  mb-0
                  text--secondary
                "
                :style="{
                  paddingLeft: `${
                    $vuetify.breakpoint.smAndUp ? '84px' : '36px'
                  }`,
                  paddingTop: `${
                    $vuetify.breakpoint.smAndUp ? '18px' : '34px'
                  }`,
                }"
              >
                Create your account
              </p>
            </v-col>
            <v-col cols="12" md="4" class="">
              <p
                class="step__text"
                :style="{
                  paddingTop: `${$vuetify.breakpoint.smAndUp ? '40px' : '3px'}`,
                  paddingLeft: `${
                    $vuetify.breakpoint.smAndUp ? '84px' : '28px'
                  }`,
                }"
              >
                Step 1 of 2
              </p>
            </v-col>
          </v-row>
          <div
            class="pt-0 pt-md-6"
            :style="{
              paddingLeft: `${$vuetify.breakpoint.smAndUp ? '86px' : '36px'}`,
              paddingRight: `${$vuetify.breakpoint.smAndUp ? '78px' : '34px'}`,
            }"
          >
            <v-form class="ma-auto" ref="form">
              <v-card-text class="pa-0">
                <div class="d-block d-md-flex field__row">
                  <v-text-field
                    background-color="#ffffff"
                    hide-details="auto"
                    ref="first_name"
                    placeholder="First Name"
                    outlined
                    class="font-weight-regular pb-1 mb-4"
                    v-model="signUpDetails.first_name"
                    :rules="rules.first_name"
                  >
                  </v-text-field>

                  <v-text-field
                    background-color="#ffffff"
                    ref="last_name"
                    hide-details="auto"
                    placeholder="Last Name"
                    outlined
                    class="font-weight-regular pb-1 mb-4"
                    v-model="signUpDetails.last_name"
                    :rules="rules.last_name"
                  >
                  </v-text-field>
                </div>

                <v-text-field
                  background-color="#ffffff"
                  hide-details="auto"
                  ref="username"
                  placeholder="Username"
                  outlined
                  class="mb-4 pb-1 font-weight-regular text-lowercase"
                  v-model="signUpDetails.username"
                  :rules="rules.uswername"
                ></v-text-field>
                <v-text-field
                  background-color="#ffffff"
                  hide-details="auto"
                  ref="email"
                  placeholder="Business Email"
                  outlined
                  class="pb-1 mb-4 font-weight-regular text-lowercase"
                  v-model="signUpDetails.email"
                  :rules="rules.email"
                ></v-text-field>

                <v-text-field
                  hide-details="auto"
                  ref="phoneNumber"
                  background-color="#ffffff"
                  placeholder="Mobile Phone. e.g. +234080990000"
                  outlined
                  class="pb-1 mb-4 font-weight-regular"
                  v-model="signUpDetails.phoneNumber"
                  :rules="rules.phoneNumber"
                ></v-text-field>

                <v-text-field
                  hide-details="auto"
                  placeholder="Password"
                  ref="password"
                  background-color="#ffffff"
                  outlined
                  class="font-weight-regular pb-1 mb-4"
                  v-model="signUpDetails.password"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPass ? 'text' : 'password'"
                  @click:append="showPass = !showPass"
                  :rules="rules.password"
                ></v-text-field>
              </v-card-text>

              <v-card-actions class="justify-center px-0">
                <v-btn
                  block
                  width="88px"
                  height="54px"
                  color="primary"
                  elevation="20"
                  :loading="isCreating"
                  large
                  class="text-capitalize"
                  @click="createAccount"
                >
                  <span
                    style="
                      font-family: Inter;
                      font-style: normal;
                      font-weight: 900;
                      font-size: 16px;
                      line-height: 19px;
                      text-align: center;
                      letter-spacing: 0.727273px;
                      color: #ffffff;
                    "
                  >
                    Continue</span
                  ></v-btn
                >
              </v-card-actions>
            </v-form>
            <div class="chip__wrapper py-2">
              <span class="info__text">Already have an account?</span>
              <router-link to="login" class="chip__link px-2"
                >Sign In Here</router-link
              >
            </div>
          </div>
        </v-card>
        <!-- second form for sign up starts -->
        <SignUp2 v-else />
        <!-- second form for sign up endss -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SignUp2 from "./SignUp2";

import { mapActions } from "vuex";
export default {
  name: "SignUp",

  data() {
    return {
      showPass: false,
      signUpDetails: {
        first_name: "",
        last_name: "",
        email: "",
        phoneNumber: "",
        username: "",
        password: "",
      },
      rules: {
        first_name: [(v) => !!v || "This field is required "],
        last_name: [(v) => !!v || "This field is required "],
        username: [(v) => !!v || "This field is required "],
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        phoneNumber: [
          (v) => !!v || "This field is required ",
          (v) => v.length == 14 || "Number is invalid",
          // (v) =>
          //   /(^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$)/.test(v) ||
          //   "Number is invalid",
        ],
        password: [
          (v) => !!v || "This field is required",
          (v) => /(?=.*?[A-Z])/.test(v) || "At least 1 Upper Case",
          (v) => /(?=.*?[a-z])/.test(v) || "At least 1 Lower Case",
          (v) => /(?=.*?[0-9])/.test(v) || "At least 1 Number",
          (v) =>
            /(?=.*?[#?!@$%^&*-])/.test(v) ||
            "At least 1 Special Character/Symbol",
          (v) => /.{6,}/.test(v) || "At least 6 Characters",
        ],
      },

      pageOne: true,
      isCreating: false,
    };
  },
  components: {
    SignUp2,
  },
  methods: {
    ...mapActions({ showToast: "ui/showToast" }),
    async createAccount() {
      Object.keys(this.form).forEach((f) => {
        //throw error to ui if there is any
        this.$refs[f].validate(true);
      });

      if (this.canCreateOrg) {
        try {
          this.isCreating = true;
          await this.$store.dispatch("auth/register", this.signUpDetails);
          // console.log(JSON.stringify(req, null, 2));

          this.showToast({
            sclass: "success",
            show: true,
            message: "Account created successfully",
            timeout: 3000,
          });
          this.$emit("nextPage");
          //now show organisation details component
          this.pageOne = false;
        } catch (err) {
          this.showToast({
            sclass: "error",
            show: true,
            message: err.msg,
            timeout: 3000,
          });
        } finally {
          this.isCreating = false;
        }
      }
    },
  },
  computed: {
    form() {
      return {
        first_name: this.signUpDetails.first_name,
        last_name: this.signUpDetails.last_name,
        email: this.signUpDetails.email,
        phoneNumber: this.signUpDetails.phoneNumber,
        password: this.signUpDetails.password,
      };
    },

    canCreateOrg() {
      // loop through rules, if all pass user can create org
      const rules = Object.keys(this.rules);
      return rules
        .map((rule) => {
          return Object.keys(this.rules[rule])
            .map((field, index) => {
              return this.rules[rule][index](this.signUpDetails[rule]);
            })
            .every((val) => val == true);
        })
        .every((val) => val == true);
    },
  },
};
</script>

<style scoped>
.card-items {
  padding-left: 86px;
  padding-right: 78px;
}

.card-input-field {
  background: #ffffff;
  border: 1px solid rgba(49, 27, 146, 0.2);
  box-sizing: border-box;
  border-radius: 3px;
}

.card-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  line-height: 29px;
}

.message-details {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  text-decoration: none;
  letter-spacing: 0.278409px;
  /*float: right;*/
  color: #19283d;

  mix-blend-mode: normal;
  opacity: 0.5;
}
.v-text-field >>> button {
  font-size: 0.8em;
}

.form-link {
  height: 32px;
  width: 325px;
  left: 0px;
  top: 0px;
  border-radius: 16px;
  background: rgba(127, 145, 155, 0.08);
}

.hint {
  height: 32px;
  width: 325px;
  left: 0px;
  top: 0px;
  border-radius: 16px;
  background: rgba(127, 145, 155, 0.08);
}
.span-text {
  height: 17px;
  left: 5.23%;
  right: 41.23%;
  top: calc(50% - 17px / 2 - 0.5px);
  padding-top: 6px;
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  letter-spacing: 0.278409px;

  color: #7f919b;
}
.span-text-link {
  height: 18px;
  left: 61.23%;
  right: 11.38%;
  top: calc(50% - 18px / 2);
  padding-left: 6px;

  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bolder;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.278409px;

  color: var(--v-primary-base);
}
.v-application .elevation-4 {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 3%), 0px 2px 2px 0px rgb(0 0 0 / 4%),
    0px 1px 5px 0px rgb(0 0 0 / 4%) !important;
}
.step__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.484848px;
  color: #002338;
  mix-blend-mode: normal;
  opacity: 0.5;
}

.chip__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 29px;
  margin-bottom: 73px;
  background: rgba(127, 145, 155, 0.08);
  border-radius: 16px;
  border: none;
}
.info__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.278409px;
  color: #7f919b;
}
.chip__link {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.278409px;
  color: #5b67ba;
  text-decoration: none;
  cursor: pointer;
}

.action__button {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.727273px;
  color: #ffffff;
}

.buttom__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.278409px;
  color: #7f919b;
}
@media only screen and (min-width: 768px) {
  /* For desktop: */
  .info__text {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 2px;
  }
  .chip__link {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 2px;
  }

  .field__row {
    column-gap: 20px;
  }
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .info__text {
    font-size: 12px;
    line-height: 13px;
  }
  .chip__link {
    font-size: 12px;
    line-height: 13px;
  }
}
</style>
