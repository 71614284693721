import purchaseorder from "../../api/purchaseorder";

const state = {
  allPOs: [],
  singlePO: null,
  converted_purchaseorder_res: null,
  documents: [],
}; //define the state

const getters = {
  getPOs(state) {
    return state.allPOs.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
  },

  getSinglePO(state) {
    return state.singlePO ? state.singlePO.data : null;
  },
};

const mutations = {
  SET_ALL_POs(state, payload) {
    state.allPOs = payload;
  },
  SET_SINGLE_PO(state, payload) {
    state.singlePO = payload;
  },

  SET_CONVERTED_PO_RESPONSE(state, payload) {
    state.converted_purchaseorder_res = payload;
  },
  setDocuments(state, invoices) {
    state.documents = invoices;
  },
};

const actions = {
  // get all purchase orders
  async getAllPOs({ commit }, organization_hypnID) {
    try {
      const { data } = await purchaseorder.getAllPOs(organization_hypnID);
      commit("SET_ALL_POs", data.data.reverse());
      if (process.env.NODE_ENV === "development") {
        // console.log(JSON.stringify(response, null, 2));
        // console.log(response);
      }

      // return a reverse sorted array of quotes
      return data.data;
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(error, null, 2));
      }
      return Promise.reject(error);
    }
  },

  // get a single PO
  async getPO({ commit }, payload) {
    try {
      const response = await purchaseorder.getPO(
        payload.organization_hypnID,
        payload.poHypnId
      );
      commit("SET_SINGLE_PO", response.data);
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(response, null, 2));
      }
      return response;
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(error, null, 2));
      }
      return Promise.reject(error);
    }
  },

  // create a new po
  async createNewPO({ commit }, reqData) {
    try {
      const response = await purchaseorder.createNewPO(
        reqData.organization_hypnID,
        reqData.payload
      );
      commit("SET_CONVERTED_QUOTE_RESPONSE", response.data);
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(response, null, 2));
      }
      return response;
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(error, null, 2));
      }
      return Promise.reject(error);
    }
  },

  // update a purchase order
  async updatePO({ commit }, reqData) {
    try {
      const response = await purchaseorder.updatePO(
        reqData.organization_hypnID,
        reqData.poHypnId,
        reqData.payload
      );
      commit("SET_SINGLE_PO", response.data);
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(response, null, 2));
      }
      return response;
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(error, null, 2));
      }
      return Promise.reject(error);
    }
  },

  async fetchDocuments({ commit, rootState }) {
    try {
      const response = await purchaseorder.getDocuments(
        rootState.organizations.fullOrganizationObject?.hypn_id
      );
      commit("setDocuments", response.data.data);
      return response.data.data;
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(error, null, 2));
      }
      return Promise.reject(error);
    }
  },

  async addDocument({ rootState }, data) {
    try {
      const response = await purchaseorder.addDocument(
        rootState.organizations.fullOrganizationObject?.hypn_id,
        data
      );

      return response.data.data;
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(error, null, 2));
      }
      return Promise.reject(error);
    }
  },

  async mergeDocument({ rootState }, data) {
    try {
      const response = await purchaseorder.mergeDocument(
        rootState.organizations.fullOrganizationObject?.hypn_id,
        data
      );

      return response.data.data;
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(error, null, 2));
      }
      return Promise.reject(error);
    }
  },

  // trigger purchase order download
  async triggerPODownload({ commit }, organization_hypnID) {
    try {
      const response = await purchaseorder.downloadPOPDF(organization_hypnID);
      commit("SET_SINGLE_PO", response.data);
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(response, null, 2));
      }
      return response;
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(error, null, 2));
      }
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
