export default {
  data() {
    return {
      currencyDefault: {
        symbol: "", // Will be set in the created hook
        thousandsSeparator: ",",
        fractionCount: 2,
        fractionSeparator: ".",
        symbolPosition: "front",
        symbolSpacing: true,
        avoidEmptyDecimals: undefined,
      },
      currencyNoSymbol: {
        symbol: "",
        thousandsSeparator: ",",
        fractionCount: 2,
        fractionSeparator: ".",
        symbolPosition: "front",
        symbolSpacing: false,
        avoidEmptyDecimals: undefined,
      },
      currencyWithShortCode: {
        symbol: "", // Will be set in the created hook
        thousandsSeparator: ",",
        fractionCount: 2,
        fractionSeparator: ".",
        symbolPosition: "front",
        symbolSpacing: false,
        avoidEmptyDecimals: undefined,
      },
    };
  },

  created() {
    if (this.$appCurrencySymbolAndSigns) {
      this.currencyDefault.symbol =
        this.$appCurrencySymbolAndSigns.currencySign || "";
      this.currencyWithShortCode.symbol =
        this.$appCurrencySymbolAndSigns.currencySymbol || "";
    }
  },

  computed: {
    configSymbol() {
      return this.$appCurrencySymbolAndSigns?.currencySymbol;
    },
  },
};
