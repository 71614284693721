<template>
  <div class="overview-wrapper">
    <div class="overview-wrapper__header">
      <BaseTitleBar pageTitle="Overview"> </BaseTitleBar>
    </div>

    <div class="overview-wrapper__content">
      <div class="section-a">
        <div class="analytics">
          <moneyIn v-model="overview.money_in" />
          <moneyOut v-model="overview.money_out" />
        </div>

        <!-- show this only on <= 1300px -->
        <div class="card-numbers">
          <div class="card-numbers__content">
            <balance v-model="overview.balance" />
            <payables v-model="overview.payables" />
            <expected-revenue v-model="overview.revenue" />
          </div>
        </div>

        <!--  -->
        <div class="transaction-table">
          <recent-transactions />
        </div>
      </div>

      <!-- show only on big screen 1800px -->
      <div class="section-b">
        <balance v-model="overview.balance" />
        <payables v-model="overview.payables" />
        <expected-revenue v-model="overview.revenue" />
        <div class="action-area">
          <team-action v-model="overview.team" />
          <approval-action v-model="overview.approvals" />
        </div>
      </div>

      <!-- show only on medium screen down <= 1300px -->
      <div class="section-c">
        <div class="transaction-table">
          <recent-transactions />
        </div>

        <div class="action-area">
          <team-action v-model="overview.team" />
          <approval-action v-model="overview.approvals" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import BaseTitleBar from "@/components/BaseTitleBar.vue";
import moneyIn from "@/components/overview/moneyInCard.vue";
import moneyOut from "@/components/overview/moneyOutCard.vue";
import balance from "@/components/overview/balanceCard.vue";
import payables from "@/components/overview/payablesCard.vue";
import expectedRevenue from "@/components/overview/expectedCard.vue";
import teamAction from "@/components/overview/teamAction.vue";
import approvalAction from "@/components/overview/approvalAction.vue";
import recentTransactions from "@/components/overview/transactions.vue";

export default {
  name: "Overview",
  components: {
    BaseTitleBar,
    moneyIn,
    moneyOut,
    balance,
    payables,
    expectedRevenue,
    teamAction,
    approvalAction,
    recentTransactions,
  },
  data() {
    return {
      overview: {
        currency: "",
        team: [],
        approvals: [],
        revenue: null,
        balance: null,
        payables: null,
        money_out: null,
        money_in: null,
      },
    };
  },
  methods: {
    ...mapActions({ showToast: "ui/showToast" }),
    async getData() {
      try {
        const { data } = await this.$store.dispatch(
          "overview/getData",
          this.orgHypnId
        );
        this.overview = data.data;
      } catch (error) {
        this.showToast({
          sclass: "error",
          show: true,
          message: error.msg || "An error occurred",
          timeout: 3000,
        });
      }
    },
  },
  mounted() {},
  computed: {
    ...mapGetters({
      user: "auth/user",
      overviewData: "overview/overviewData",
    }),
  },
  watch: {
    orgHypnId: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.getData();
        }
      },
    },
    overviewData: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && val.data) {
          this.overview = val.data;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.overview-wrapper {
  min-height: 100vh;

  &__header {
    margin: 20px;
    @media (min-width: 768px) {
      margin: 0px;
      flex-direction: row;
    }
  }

  &__content {
    // border: 1px solid red;
    margin: 20px;
    gap: 30px;
    display: flex;
    flex-direction: column;

    @media (min-width: 1600px) {
      margin: 50px;
      flex-direction: row;
    }

    .section-a {
      flex: 1;
      // border: 1px solid black;
      @media (min-width: 1024px) and (max-width: 1300px) {
        display: flex;
        gap: 25px;
      }

      .analytics {
        gap: 25px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);

        @media (min-width: 1024px) and (max-width: 1300px) {
          flex: 1;
        }

        @media (min-width: 1300px) {
          grid-template-columns: repeat(2, 1fr);
        }
      }

      .card-numbers {
        @media (max-width: 1600px) {
          margin-top: 25px;
        }

        &__content {
          height: 100%;
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
          gap: 25px;
        }

        @media (min-width: 1600px) {
          display: none;
        }
      }

      .transaction-table {
        margin-top: 25px;
      }

      @media (max-width: 1600px) {
        .transaction-table {
          display: none;
        }
      }
    }

    .section-b {
      gap: 25px;
      display: none;
      // border: 1px solid blue;

      @media (min-width: 1600px) {
        display: block;
        display: flex;
        flex-direction: column;
        width: 30%;
      }
    }

    .section-c {
      // @media (min-width: 1300px) {
      //   display: none;
      // }

      display: none;
      @media (max-width: 1600px) {
        display: block;
      }
    }

    .transaction-table {
      background-color: #fff;
      height: 500px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.0278628);
      border-radius: 4px;
    }

    .action-area {
      margin-top: 25px;
    }
  }
}
</style>
