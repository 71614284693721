<template>
  <div class="overview-team">
    <div class="overview-team__content">
      <span class="title">Your Team</span>
      <div class="members">
        <span class="invite" v-if="!value || (value && value.length === 0)"
          >Invite coworker</span
        >
        <div class="avatar-stack" v-if="value">
          <v-tooltip bottom v-for="(team, i) in value.slice(0, 3)" :key="i">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="team-avatar"
                v-bind="attrs"
                v-on="on"
                :style="{ 'background-color': randomColor[i] }"
              >
                {{
                  (team.first_name &&
                    team.first_name.charAt(0).toUpperCase()) ||
                  ""
                }}
              </div>
            </template>
            <span>{{ team.first_name + " " + team.last_name }}</span>
          </v-tooltip>
        </div>
      </div>
      <div class="add-team">
        <InviteStakeholder>
          <button>+</button>
        </InviteStakeholder>
      </div>
    </div>
  </div>
</template>

<script>
import InviteStakeholder from "@/pages/invoices/components/inviteStakeholder.vue";
export default {
  name: "teamAction",
  components: {
    InviteStakeholder,
  },
  props: {
    value: {
      default: () => null,
    },
  },
  data() {
    return {};
  },
  computed: {
    randomColor() {
      const colors = [
        "#f44336",
        "#e91e63",
        "#9c27b0",
        "#673ab7",
        "#3f51b5",
        "#2196f3",
        "#03a9f4",
        "#00bcd4",
        "#009688",
        "#4caf50",
        "#8bc34a",
        "#cddc39",
        "#ffeb3b",
        "#ffc107",
        "#ff9800",
        "#ff5722",
        "#795548",
        "#9e9e9e",
        "#607d8b",
      ];
      // randomize the colors
      return colors.sort(() => Math.random() - 0.5);
    },
  },
};
</script>

<style lang="scss" scoped>
.overview-team {
  border-bottom: 1px dashed #d9dee1;
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px 10px;

    .title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: rgba(25, 40, 61, 0.8);
    }

    .members {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0px 10px;

      .invite {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #8f96a1;
        opacity: 0.7;
      }

      .avatar-stack {
        position: relative;
        display: flex;
        margin-right: 12px;

        .team-avatar {
          position: relative;
          width: 45px;
          height: 45px;
          border-radius: 50%;
          color: #fff;
          font-weight: 600;
          overflow: hidden;
          margin-right: -12px;
          border: 5px solid #fff;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .add-team {
      border-left: 2.31579px solid #a9a9a9;
      padding-left: 10px;

      button {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background: rgba(127, 145, 155, 0.052607);
        border: 6px solid #fff;

        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 27px;
        text-align: center;
        color: #a9a9a9;
        opacity: 0.7;
      }
    }
  }
}
</style>
