<template>
  <v-container class="px-0 cards">
    <v-skeleton-loader :type="getSkeletonLoaderType" :loading="isLoading">
      <v-layout
        row
        wrap
        class="align-center my-2 px-8"
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <v-flex md2>
          <div class="d-flex align-center">
            <p class="mb-0 primary--text font-weight-bold text-center">
              Direction
            </p>
          </div>
        </v-flex>
        <v-flex md2>
          <div class="d-flex align-center">
            <p class="mb-0 pl-3 primary--text font-weight-bold">Amount</p>
          </div>
        </v-flex>
        <v-flex md2>
          <div class="d-flex align-center">
            <p class="mb-0 pl-9 primary--text font-weight-bold text-center">
              Type
            </p>
          </div>
        </v-flex>
        <v-flex md2>
          <div class="d-flex align-center">
            <p class="mb-0 primary--text font-weight-bold">Ref No.</p>
          </div>
        </v-flex>

        <v-flex md3>
          <div class="d-flex align-center">
            <p class="mb-0 pl-1 primary--text font-weight-bold">Contact</p>
          </div>
        </v-flex>
        <v-flex md1>
          <div>
            <p class="mb-0 pl-2 primary--text font-weight-bold">Date</p>
          </div>
        </v-flex>
        <!-- <v-flex md2>
          <div>
            <p class="mb-0 pl-md-3 primary--text font-weight-bold">Status</p>
          </div>
        </v-flex> -->
      </v-layout>
      <div v-if="displayedItems && displayedItems.length > 0">
        <!-- Render table rows -->
        <div v-for="(row, index) in displayedItems" :key="index">
          <RecentTransactionTableRow
            v-if="$vuetify.breakpoint.mdAndUp"
            :directiontag="row.direction"
            :account="row.type"
            :transactionRef="row.reference"
            :paymentDate="row.date | date"
            :contact="row.contact"
            :status="row.status"
            :amount="row.amount | currency(currencyNoSymbol)"
            :index="index"
          />

          <RecentTransactionTableCards
            v-if="$vuetify.breakpoint.smAndDown"
            :directiontag="row.direction"
            :account="row.type"
            :transactionRef="row.reference"
            :paymentDate="row.date | date"
            :contact="row.contact"
            :status="row.status"
            :amount="row.amount | currency(currencyNoSymbol)"
            :index="index"
          />
        </div>
      </div>

      <!-- Show empty state if no transactions -->
      <div class="d-flex justify-center align-center py-10 mt-8" v-else>
        <BaseEmpty
          :title="`You do not have any recent \n transaction`"
          description="Start sending or receiving payment"
        />
      </div>

      <!-- Pagination -->
      <div
        class="pagination__container px-8"
        v-if="displayedItems && displayedItems.length > 0"
      >
        <BasePagination
          :current-page="currentPage"
          :visible-pages="visiblePageValue"
          :total-pages="totalPages"
          @page-changed="handlePageChange"
        />
      </div>
    </v-skeleton-loader>
  </v-container>
</template>

<script>
import BaseEmpty from "@/components/BaseEmptyState.vue";
import RecentTransactionTableRow from "./RecentTransactionTableRow.vue";
import RecentTransactionTableCards from "./RecentTransactionTableCards.vue";
import currencyFilter from "@/mixins/currencyFilter";
import pagination from "@/mixins/pagination";
import BasePagination from "@/components/BasePagination.vue";
import { mapGetters } from "vuex";

export default {
  name: "RecentTransactionPage",
  mixins: [currencyFilter, pagination],
  components: {
    BaseEmpty,
    RecentTransactionTableRow,
    RecentTransactionTableCards,
    BasePagination,
  },

  data() {
    return {
      isLoading: false,
      // itemsPerPage: 2,
      filteredTransactions: [],
      filterDateValue: 0,
      filterCurrencyValue: 0,
    };
  },

  computed: {
    ...mapGetters({
      organizationToken: "organizations/OrganToken",
      transactions: "transactions/getAllTransactions",
    }),

    getSkeletonLoaderType() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return "table"; // Use table type for desktop and larger screens
      } else {
        return "card"; // Use card type for mobile and smaller screens
      }
    },
    // get the length of the filtered form array to use it as the value of the visible page
    visiblePageValue() {
      const pageNumber = Math.floor(
        this.transactions?.length / this.itemsPerPage
      );
      return pageNumber > 5 ? 5 : pageNumber < 1 ? 1 : pageNumber;
    },
    // get array per page
    displayedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      if (
        this.filteredTransactions &&
        this.filteredTransactions?.length > this.itemsPerPage
      ) {
        // Use this.filteredTransactions instead of this.transactions
        let arraySubset = this.filteredTransactions?.slice(
          startIndex,
          endIndex
        );
        return arraySubset?.map((item, index) => {
          if (this.currentPage === 1) {
            return {
              index: index + 1,
              ...item,
            };
          } else {
            return {
              index: index + 1 + (this.currentPage - 1) * 10,
              ...item,
            };
          }
        });
      } else {
        let arraySubset = this.filteredTransactions?.slice();
        return arraySubset?.map((item, index) => {
          if (this.currentPage === 1) {
            return {
              index: index + 1,
              ...item,
            };
          } else {
            return {
              index: index + 1 + (this.currentPage - 1) * 10,
              ...item,
            };
          }
        });
      }
    },

    totalPages() {
      return Math.ceil(this.transactions?.length / this.itemsPerPage);
    },
    itemsPerPage() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 5; // Desktop devices
      } else {
        return 2; // Mobile devices (adjust the breakpoint if needed)
      }
    },
  },

  methods: {
    async getAllTransactions() {
      this.isLoading = true;
      const organHypnId = await this.organizationToken?.data?.hypn_id;

      const dataObject = {
        organization_hypnID: organHypnId,
        start: 0,
        limit: -1,
      };

      try {
        // Dispatch action to fetch transactions
        await this.$store.dispatch(
          "transactions/getOrgTransactions",
          dataObject
        );
      } catch (error) {
        console.log(error);
      } finally {
        setTimeout(() => {
          this.isLoading = false;
        }, 4000);
      }
    },
    // No filter Data
    filterAllTime() {
      return this.transactions;
    },

    filterAllCuren() {
      return this.transactions;
    },

    // Filter array by date - Last 24 Hours
    filter24Hours() {
      const now = new Date();
      const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000); // Subtract 24 hours (1 day)

      return this.transactions?.filter((payment) => {
        const date = new Date(payment.date);
        return date > twentyFourHoursAgo;
      });
    },

    // Filter array by date - Last 7 Days
    filterLast7Days() {
      const now = new Date();
      const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000); // 7 days ago in milliseconds
      return this.transactions?.filter((payment) => {
        const date = new Date(payment.date);
        return date > sevenDaysAgo;
      });
    },

    // Filter array by date - Last 30 Days
    filterLast30Days() {
      const now = new Date();
      const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000); // 30 days ago in milliseconds
      return this.transactions?.filter((payment) => {
        const date = new Date(payment.date);
        return date > thirtyDaysAgo;
      });
    },

    async filterByDate(val) {
      this.filterDateValue = val;
      let filteredTransactions;
      // console.log(this.filterDateValue);
      switch (val) {
        case 0:
          filteredTransactions = await this.filterAllTime();
          break;
        case 1: // Last 24 Hours
          filteredTransactions = await this.filter24Hours();
          break;
        case 7: // Last 7 Days
          filteredTransactions = await this.filterLast7Days();
          break;
        case 30: // Last 30 Days
          filteredTransactions = await this.filterLast30Days();
          break;
      }

      this.filteredTransactions = filteredTransactions; // Update the filtered transactions
      this.currentPage = 1;
    },

    // filter by currency
    // async filterByCurrency(val) {

    // }
  },

  // mounted() {
  //     this.filterByDate(this.filterDateValue);
  //     console.log(this.filterDateValue)

  // },

  watch: {
    "organizationToken.data.hypn_id": {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.getAllTransactions();
        }
      },
    },

    transactions: {
      deep: true,
      immediate: true,
      handler() {
        this.filterByDate(this.filterDateValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination__container {
  display: flex;
  justify-content: right;
  align-items: center;
}
</style>
