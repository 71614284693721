<template>
  <div class="base-empty-state">
    <div class="base-empty-state__content">
      <img
        :src="require('@/assets/base-empty.svg')"
        alt="folder svg"
        class="base-img"
      />
      <div class="base-title">
        {{ title }}
      </div>
      <pre class="base-desc">
        {{ description }}
        
      </pre>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },

  computed: {
    ...mapGetters({
      organizationToken: "organizations/OrganToken",
    }),
  },
  methods: {
    openCreateInvoice() {
      // open Make customer Invoice page

      this.$router.push({ name: "NewInvoice" });
    },
  },
};
</script>

<style lang="scss" scoped>
.base-empty-state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 20px;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    .base-img {
      width: 76px;
      max-width: 100px;
      height: 68.88px;
    }
    .base-title {
      margin-top: 10px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 21px;
      line-height: 28px;
      text-align: center;
      max-width: 500px;
      color: rgba(25, 40, 61, 0.8);
    }

    .base-desc {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      max-width: 400px;
      text-align: center;
      color: #525e6e;
    }
  }
}
</style>
