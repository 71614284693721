<template>
  <v-card class="pb-3 mt-10" color="transparent" elevation="0">
    <v-row class="pa-0 ma-0 d-flex flex-row align-center justify-center">
      <v-col cols="12" md="8" class="pa-0">
        <p
          class="
            text-md-h4 text-h5
            font-weight-bold
            pb-md-4
            mb-0
            text--secondary
          "
          :style="{
            paddingLeft: `${$vuetify.breakpoint.smAndUp ? '84px' : '36px'}`,
            paddingTop: `${$vuetify.breakpoint.smAndUp ? '18px' : '34px'}`,
          }"
        >
          Create your account
        </p>
      </v-col>
      <v-col cols="12" md="4" class="pl-9 pl-md-4 pa-0">
        <p
          class="step__text"
          :style="{
            paddingTop: `${$vuetify.breakpoint.smAndUp ? '44px' : '3px'}`,
            paddingLeft: `${$vuetify.breakpoint.smAndUp ? '84px' : '28px'}`,
          }"
        >
          Step 2 of 2
        </p>
      </v-col>
    </v-row>
    <div class="">
      <p
        class="text-break"
        style="
          padding-left: 84px;
          padding-right: 78px;
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.278409px;
          color: #7f919b;
        "
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        Hello {{ user.username }}, to get great value from Hyphen please provide
        information about your company below
      </p>
    </div>
    <div
      :style="{
        paddingLeft: `${$vuetify.breakpoint.smAndUp ? '86px' : '36px'}`,
        paddingRight: `${$vuetify.breakpoint.smAndUp ? '78px' : '34px'}`,
      }"
    >
      <v-form @submit.prevent="onSubmit" class="ma-auto" ref="form">
        <v-card-text class="pa-0">
          <v-row class="pt-2">
            <v-col cols="12">
              <v-text-field
                ref="orgname"
                hide-details="auto"
                :rules="rules.orgname"
                height="48"
                placeholder="Company Name"
                background-color="#ffffff"
                outlined
                class="font-weight-regular text-subtitle-2 pb-1 mt-3 mb-4"
                v-model="companyDetails.orgname"
              ></v-text-field>

              <v-select
                ref="country"
                hide-details="auto"
                :rules="rules.country"
                placeholder="Country of Operation"
                outlined
                background-color="#ffffff"
                class="font-weight-regular text-subtitle-2 pb-1 mb-4"
                v-model="companyDetails.country"
                :items="operatingCountries"
              ></v-select>

              <v-text-field
                ref="cac"
                background-color="#ffffff"
                hide-details="auto"
                :rules="rules.cac"
                placeholder="CAC Reg Number/Tax ID"
                outlined
                class="font-weight-regular text-subtitle-2 pb-1 mb-4"
                v-model="companyDetails.cac"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="justify-center px-0">
          <v-btn
            @click="onSubmit"
            block
            width="88px"
            height="54px"
            color="primary"
            class="text-capitalize"
            elevation="20"
            large
          >
            <span
              style="
                font-family: Inter;
                font-style: normal;
                font-weight: 900;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                letter-spacing: 0.727273px;
                color: #ffffff;
              "
            >
              Continue
            </span>
          </v-btn>
        </v-card-actions>
      </v-form>

      <div class="chip__wrapper py-2">
        <span class="info__text">Already have an account?</span>
        <router-link to="login" class="chip__link px-2"
          >Sign In Here</router-link
        >
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      snackbar: {
        snackbar: false,
        text: null,
        sclass: null,
        timeout: 1000,
      },
      isCreating: false,
      companyDetails: {
        orgname: "",
        country: "",
        cac: "",
      },
      operatingCountries: [
        "Nigeria",
        "United States of America",
        "UAE",
        "Ghana",
      ],

      rules: {
        orgname: [(v) => !!v || "This field is required "],
        country: [(v) => !!v || "This field is required "],
        cac: [(v) => !!v || "This field is required "],
      },
    };
  },
  methods: {
    ...mapActions({ showToast: "ui/showToast" }),
    async onSubmit() {
      Object.keys(this.form).forEach((f) => {
        //throw error to ui if there is any
        this.$refs[f].validate(true);
      });

      if (this.canCompleOrgCreation) {
        const payload = {
          orgname: this.companyDetails.orgname,
          members: [this.user.id],
          company: {
            company_name: this.companyDetails.orgname,
            registration_number: this.companyDetails.cac,
            country: this.companyDetails.country,
          },
        };

        try {
          this.isCreating = true;
          await this.$store.dispatch(
            "organizations/createOrganization",
            payload
          );

          this.showToast({
            message: "Organization created successfully",
            sclass: "success",
            show: true,
          });

          let userId = localStorage.getItem("userId");
          await this.$store
            .dispatch("auth/fetchUserDetails", userId)
            .then(async () => {
              this.showToast({
                message: "fetcging organization details",
                sclass: "success",
                show: true,
              });
              await this.$store.dispatch("organizations/getOrganizationToken");
            })
            .then(async () => {
              await this.$store.dispatch(
                "organizations/getOrganizationById",
                this.$store.state.auth.user.organization
              );
            });

          this.showToast({
            message: "Welcome",
            show: true,
            sclass: "success",
          });

          //sucess creating org
          this.$router.replace("/welcome");
        } catch (error) {
          if (process.env.NODE_ENV === "development") {
            console.log(JSON.stringify(error, null, 2));
          }
          this.showToast({
            sclass: "error",
            show: true,
            message: error.msg,
            timeout: 3000,
          });
        } finally {
          this.isCreating = false;
        }
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    form() {
      return {
        orgname: this.companyDetails.orgname,
        country: this.companyDetails.country,
        cac: this.companyDetails.cac,
      };
    },

    canCompleOrgCreation() {
      // loop through rules, if all pass user can create org
      const rules = Object.keys(this.rules);
      return rules
        .map((rule) => {
          return Object.keys(this.rules[rule])
            .map((field, index) => {
              return this.rules[rule][index](this.companyDetails[rule]);
            })
            .every((val) => val == true);
        })
        .every((val) => val == true);
    },
  },
};
</script>

<style scoped>
.card-input-field {
  background: #ffffff;
  border: 1px solid rgba(49, 27, 146, 0.2);
  box-sizing: border-box;
  border-radius: 3px;
}

.card-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  line-height: 29px;
}

.v-text-field >>> input {
  font-size: 1em;
  font-weight: 300;
  text-transform: capitalize;
}

.message-details {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
  letter-spacing: 0.278409px;
  float: right;
  color: #19283d;

  mix-blend-mode: normal;
  opacity: 0.5;
}
.v-text-field >>> button {
  font-size: 0.8em;
}

/* .form-link {
  height: 32px;
  width: 325px;
  left: 0px;
  top: 0px;
  border-radius: 16px;
  background: rgba(127, 145, 155, 0.08);
} */

.hint {
  height: 32px;
  width: 325px;
  left: 0px;
  top: 0px;
  border-radius: 16px;
  background: rgba(127, 145, 155, 0.08);
}
.span-text {
  height: 17px;
  left: 5.23%;
  right: 41.23%;
  top: calc(50% - 17px / 2 - 0.5px);
  padding-top: 6px;
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  letter-spacing: 0.278409px;

  color: #7f919b;
}
.span-text-link {
  height: 18px;
  left: 61.23%;
  right: 11.38%;
  top: calc(50% - 18px / 2);
  padding-left: 6px;

  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bolder;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.278409px;

  color: var(--v-primary-base);
}

.v-application .elevation-4 {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 3%), 0px 2px 2px 0px rgb(0 0 0 / 4%),
    0px 1px 5px 0px rgb(0 0 0 / 4%) !important;
}
.step__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.484848px;
  color: #002338;
  mix-blend-mode: normal;
  opacity: 0.5;
}

.chip__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 29px;
  margin-bottom: 73px;
  background: rgba(127, 145, 155, 0.08);
  border-radius: 16px;
  border: none;
}
.info__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.278409px;
  color: #7f919b;
}
.chip__link {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.278409px;
  color: #5b67ba;
  text-decoration: none;
  cursor: pointer;
}

.action__button {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.727273px;
  color: #ffffff;
}

.buttom__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.278409px;
  color: #7f919b;
}
@media only screen and (min-width: 768px) {
  /* For desktop: */
  .info__text {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 2px;
  }
  .chip__link {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 2px;
  }
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .info__text {
    font-size: 12px;
    line-height: 13px;
  }
  .chip__link {
    font-size: 12px;
    line-height: 13px;
  }
}
</style>
