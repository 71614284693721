import MainLayout from "@/layouts/MainLayout.vue";

const routes = [
  {
    path: "/procurement",
    name: "Quote",
    components: {
      default: () => import("@/pages/quotes/index.vue"),
      MainLayout,
    },
    meta: { middleware: ["auth", "tools"] },
  },

  {
    path: "/quote/new-quote",
    name: "createQuote",
    components: {
      default: () => import("@/pages/quotes/new-quote.vue"),
      MainLayout,
    },
    meta: { middleware: ["auth", "tools"] },
  },
  // {
  //   path: "/inbox/request-payment",
  //   name: "RequestPayment",
  //   components: {
  //     default: () => import("@/pages/invoices/RequestPayment.vue"),
  //     MainLayout,
  //   },
  //   meta: { middleware: ["auth", "tools"] },
  // },
  {
    path: "/preview-quote/:id",
    name: "previewQuote",
    components: {
      default: () => import("@/pages/quotes/QPreview.vue"),
      MainLayout,
    },
    meta: { middleware: ["auth", "tools"] },
  },
  {
    path: "/quote/edit/:id",
    name: "createQuote",
    components: {
      default: () => import("@/pages/quotes/new-quote.vue"),
      MainLayout,
    },
    meta: { middleware: ["auth", "tools"] },
  },
  {
    path: "/purchase-order/edit/:id",
    name: "createPurchaseOrder",
    components: {
      default: () => import("@/pages/quotes/new-purchase-order.vue"),
      MainLayout,
    },
    meta: { middleware: ["auth", "tools"] },
  },
  {
    path: "/purchase-order/new",
    name: "createPurchaseOrder",
    components: {
      default: () => import("@/pages/quotes/new-purchase-order.vue"),
      MainLayout,
    },
    meta: { middleware: ["auth", "tools"] },
  },
  {
    path: "/preview-purchase-order/:id",
    name: "previewPurchaseOrder",
    components: {
      default: () => import("@/pages/quotes/POPreview.vue"),
      MainLayout,
    },
    meta: { middleware: ["auth", "tools"] },
  },
];

export default routes;
