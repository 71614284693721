<template>
  <div class="overview-payables">
    <span class="mtitle">Payables</span>
    <div class="content-row">
      <span class="amount" ref="totalPayables">{{
        value && (value.total === 0 || value.total === "")
          ? `${$appCurrencySymbolAndSigns.currencySign}0.00`
          : "--"
      }}</span>
      <span class="action">
        <img :src="require('@/assets/payables-view-all.svg')" alt="" />
        <router-link to="/payable" class="content">view all</router-link>
      </span>
    </div>
    <span class="message"
      >Next Due <span>{{ (value && value.next) || "--" }}</span></span
    >
  </div>
</template>

<script>
import { CountUp } from "countup.js";

export default {
  props: {
    value: {
      default: () => null,
    },
  },
  methods: {
    setValues(val) {
      const options = {
        useEasing: true,
        duration: 3,
        decimalPlaces: 2,
        formattingFn: (n) =>
          Intl.NumberFormat("en-NG", {
            currency: this.$appCurrencySymbolAndSigns.currencySymbol,
            style: "currency",
          }).format(n),
      };
      if (val.total) {
        new CountUp(this.$refs.totalPayables, val.total, options).start();
      }
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            setTimeout(() => {
              this.setValues(val);
            }, 2500);
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.overview-payables {
  padding: 20px;
  border: 1px solid #f1dfd0;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.0278628));
  border-radius: 4px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: 768px) {
    padding: 30px 40px;
  }

  .mtitle {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    color: rgba(25, 40, 61, 0.8);
  }

  .content-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    line-height: 10px;

    font-family: "Inter";
    font-style: normal;

    .amount {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 21px;
      line-height: 24px;
      color: #19283d;
      min-width: 100px;
    }

    .action {
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;
      padding: 1px;

      .content {
        font-weight: 600;
        font-size: 12px;
        text-decoration-line: underline;
        color: #5b67ba;
        padding: 1px;
        cursor: pointer;
      }
    }
  }

  .message {
    margin-top: 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #8f96a1;

    span {
      padding-left: 10px;
    }
  }
}
</style>
