import api from "./Api";
export default {
  getData(organization_hypnID) {
    return api().get(`organizations/${organization_hypnID}/dashboard`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },
};
