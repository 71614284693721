<template>
  <div>
    <v-hover v-slot:default="{ hover }">
      <v-card
        class="px-7 py-6 my-1"
        style="cursor: pointer; transition: all 0.3s ease"
        :elevation="hover ? 2 : 0"
        :color="index % 2 ? '#f6f6f6' : ''"
      >
        <v-layout row wrap class="align-center">
          <v-flex md2>
            <div class="d-flex align-center">
              <v-chip
                :color="chipcolor"
                :text-color="textColor"
                class="chip__text"
                x-small
                >{{ directiontag }}</v-chip
              >
            </div>
          </v-flex>
          <v-flex md2>
            <div class="d-flex align-center pl-4">
              <span class="mb-0 text-center row__text" style="">{{
                amount
              }}</span>
            </div>
          </v-flex>

          <v-flex md2>
            <div class="d-flex align-center">
              <span class="mb-0 pl-10 row__text">{{ account }}</span>
            </div>
          </v-flex>
          <v-flex md2>
            <div class="d-flex align-center">
              <span class="mb-0 row__text">{{ transactionRef }}</span>
            </div>
          </v-flex>
          <v-flex md3>
            <div class="d-flex align-center px-1">
              <span class="mb-0 text-truncate row__text">{{ contact }}</span>
            </div>
          </v-flex>
          <v-flex md1>
            <div class="d-flex align-center justify-right">
              <span
                class="mb-0 text-right text-capitalize text-truncate row__text"
                >{{ paymentDate }}</span
              >
            </div>
          </v-flex>
          <!-- <v-flex md2>
            <div class="d-flex align-center justify-start">
              <v-icon left :color="statusIcon" class="ma-0 px-0"
                >mdi-circle-small</v-icon
              >
              <span class="mb-0 row__text">{{ status }}</span>
            </div>
          </v-flex> -->
        </v-layout>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
export default {
  name: "RecentTransactionTableRow",
  props: {
    directiontag: {
      type: String,
    },
    index: {
      type: Number,
    },
    amount: {
      type: [String, Number],
    },

    account: {
      type: String,
    },
    transactionRef: {
      type: String,
    },
    paymentDate: {
      type: String,
    },

    contact: {
      type: [String, Number],
    },
    status: {
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    statusIcon() {
      if (this.status === "pending") {
        return "#E3AA1C";
      } else if (this.status === "in review") {
        return "#2B99D5";
      } else return "#2BD5AE";
    },
    chipcolor() {
      if (this.directiontag === "credit") {
        return "#EEFCF9";
      } else return "#FDF9EF";
    },
    textColor() {
      if (this.directiontag === "credit") {
        return "#2BD5AE";
      } else return "#F7CA40";
    },
  },
};
</script>

<style scoped>
.row__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #596a73;
}

.chip__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
}

.v-application .elevation-2 {
  box-shadow: -6px 3px 1px -2px rgb(0 0 0 / 0%), 0px 2px 2px 0px rgb(0 0 0 / 0%),
    0px 1px 5px 0px rgb(0 0 0 / 7%) !important;
}
</style>
