import MainLayout from "@/layouts/MainLayout.vue";

const routes = [
  {
    path: "/transactions",
    name: "transactions",
    components: {
      default: () => import("@/pages/transactions/index.vue"),
      MainLayout,
    },
    meta: { middleware: ["auth", "payment"] },
  },
  // {
  //     path: "/contacts/:id",
  //     name: "contact.customer",
  //     props: true,
  //     components: {
  //         default: () =>
  //             import ("@/pages/contacts/_id.vue"),
  //         MainLayout,
  //     },
  //     meta: { middleware: ["auth"] },
  // },
];

export default routes;
