import MainLayout from "@/layouts/MainLayout.vue";

const routes = [
  {
    path: "/inbox",
    name: "Inbox",
    components: {
      default: () => import("@/pages/invoices/index.vue"),
      MainLayout,
    },
    meta: { middleware: ["auth", "tools"] },
  },

  {
    path: "/inbox/new-invoice",
    name: "NewInvoice",
    components: {
      default: () => import("@/pages/invoices/new-invoice.vue"),
      MainLayout,
    },
    meta: { middleware: ["auth", "tools"] },
  }, // {
  {
    path: "/inbox/request-payment",
    name: "RequestPayment",
    components: {
      default: () => import("@/pages/invoices/RequestPayment.vue"),
      MainLayout,
    },
    meta: { middleware: ["auth", "tools"] },
  }, // {
  //     path: "/inbox/new",
  //     name: "NewWorkFlow",
  //     components: {
  //         default: () =>
  //             import ("@/pages/invoices/new.vue"),
  //         MainLayout,
  //     },
  //     meta: { middleware: ["auth"] },
  // },
  {
    path: "/inbox/:id",
    name: "inbox.invoice",
    components: {
      default: () => import("@/pages/invoices/_id.vue"),
      MainLayout,
    },
    meta: { middleware: ["auth", "tools"] },
  },
];

export default routes;
