import MainLayout from "@/layouts/MainLayout.vue";

const routes = [
  {
    path: "/approvals",
    name: "approvals",
    components: {
      default: () => import("@/pages/approvals/index.vue"),
      MainLayout,
    },
    meta: { middleware: ["auth", "approvals"] },
  },
];

export default routes;
