import api from "./Api";
export default {
  addInvoiceToPayable(payload) {
    return api().post("transactions/payables", payload, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },

  //get all payables for an organization

  getAllPayables(organization_hypnID) {
    return api().get(`transactions/payables/${organization_hypnID}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },

  // get a single payable
  getSinglePayable(organization_hypnID, payable_id) {
    return api().get(
      `transactions/payables/${organization_hypnID}/${payable_id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
  },
};
