import api from "./Api";

export default {
  create(organization) {
    return api().post("/organizations", organization, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },

  async getInviteOrg(organizationId) {
    return await api().get("/organization/portal/" + organizationId, {
      headers: {
        "x-api-key": sessionStorage.getItem("invitetoken"),
      },
    });
  },

  update(orgId, credentials) {
    return api().put("/organizations/" + orgId, credentials, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },

  async getOrganById(organizationId) {
    return await api().get("/organizations/" + organizationId, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },
  async getOrganizationToken(organizationId) {
    return await api().get(`/organizations/${organizationId}/token`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },
  async uploadOrganCac(payload) {
    return await api().post(`/upload`, payload, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    });
  },
  async uploadIdCard(payload) {
    return await api().post(`/upload`, payload, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    });
  },
  async uploadOrganizationLogo(payload) {
    return await api().post(`/upload`, payload, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    });
  },

  addBankAccount(organizationId, bankCode) {
    return api().put(
      "/organizations/" + organizationId + "/addBank",
      bankCode,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
  },

  // get all accounts for an oranization
  getAllAccounts(organizationId) {
    return api().get(`accounts/${organizationId}`, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  getBankAccounts(organizationId) {
    return api().get("/organizations/" + organizationId + "/bankAccounts", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },
  inviteCoWorker(organizationId, payload) {
    return api().post(`organizations/${organizationId}/addmember`, payload, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },

  getCoWorkers(organizationId) {
    return api().get(`/organizations/${organizationId}/members`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },

  getBanks() {
    return api().get(`/banks/list`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },

  sendEmail(email) {
    return api().post("/organizations/email", email);
  },

  getOrganizationSettings(id) {
    return api().get(`/settings/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },

  // get a user profile for settings..
  updateUserProfileDetails(payload) {
    return api().put(`/organizations/profile/me`, payload, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },
};
