<template>
  <div class="pagination">
    <ul>
      <li v-if="showFirstButton" @click="changePage(1)">First</li>
      <li v-if="showPreviousButton" @click="changePage(currentPage - 1)">
        Previous
      </li>
      <li
        v-for="pageNumber in visiblePageNumbers"
        :key="pageNumber"
        :class="{ active: pageNumber === currentPage }"
        @click="changePage(pageNumber)"
      >
        {{ pageNumber }}
      </li>
      <li v-if="showNextButton" @click="changePage(currentPage + 1)">Next</li>
      <li v-if="showLastButton" @click="changePage(totalPages)">Last</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    visiblePages: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      currentPageNumber: this.currentPage,
    };
  },
  computed: {
    showFirstButton() {
      return this.currentPage >= 1;
    },
    showLastButton() {
      return this.currentPage <= this.totalPages;
    },
    showPreviousButton() {
      return this.currentPage > 1;
    },
    showNextButton() {
      return this.currentPage < this.totalPages;
    },
    visiblePageNumbers() {
      const middlePage = Math.floor(this.visiblePages / 2) + 1;
      const startPage = Math.max(1, this.currentPage - middlePage);
      const endPage = Math.min(
        this.totalPages,
        this.currentPage + middlePage - 1
      );
      const visiblePages = [];

      for (let i = startPage; i <= endPage; i += 1) {
        visiblePages.push(i);
      }

      return visiblePages;
    },
  },
  methods: {
    changePage(pageNumber) {
      this.currentPageNumber = pageNumber;
      this.$emit("page-changed", pageNumber);
    },
  },
};
</script>

<style>
.pagination {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  margin-top: 10px;
}

.pagination ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.pagination li {
  margin-left: 6px;
  padding: 4px 8px;
  border: 1px solid #19283d;
  border-radius: 5px;
  cursor: pointer;
  color: #19283d;
  font-size: small;
}

/* z-index: 3;
  color: #fff;
  background-color: #19283d;
  border-color: #19283d; */
.pagination li.active {
  background-color: #19283d;

  color: #fff;
}

.pagination li:hover:not(.active) {
  background-color: #ddd;
}
</style>
