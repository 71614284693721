import MainLayout from "@/layouts/MainLayout.vue";

const routes = [
  {
    path: "/contacts",
    name: "Contacts",
    components: {
      default: () => import("@/pages/contacts/index.vue"),
      MainLayout,
    },
    meta: { middleware: ["auth", "intelligence"] },
  },
  {
    path: "/contacts/:id",
    name: "contact.customer",
    props: true,
    components: {
      default: () => import("@/pages/contacts/_id.vue"),
      MainLayout,
    },
    meta: { middleware: ["auth", "intelligence"] },
  },
];

export default routes;
