<template>
  <div>
    <span @click="OpenDialog">
      <slot></slot>
    </span>
    <v-dialog v-model="dialog" max-width="516">
      <v-card color="#f8f7f4" class="rounded-lg">
        <v-card-title
          class="px-8"
          style="background: #ffffff; border-radius: 8px 8px 0px 0px"
        >
          <span class="modal__title">Invite a Stakeholder</span>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog" icon color="primary">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <div
          class="px-8 d-flex align-center sub__title"
          style="background: #f3eee8; min-height: 54px"
        >
          Add details of <span class="pl-1 font-weight-bold"> Stakeholder</span>
        </div>

        <v-form ref="form">
          <div class="pt-10 form__container">
            <div class="field__row">
              <v-text-field
                v-model="stakeholder.firstName"
                hide-details="auto"
                type="text"
                background-color="#ffffff"
                outlined
                placeholder="First Name"
                :rules="rules.first_name"
              >
              </v-text-field>
              <v-text-field
                v-model="stakeholder.lastName"
                hide-details="auto"
                type="text"
                background-color="#ffffff"
                outlined
                placeholder="Last Name"
                :rules="rules.last_name"
              >
              </v-text-field>
            </div>

            <div class="field__row">
              <v-text-field
                v-model="stakeholder.email"
                hide-details="auto"
                type="email"
                background-color="#ffffff"
                outlined
                placeholder="Email Address"
                :rules="rules.email"
              >
              </v-text-field>
            </div>

            <div class="field__row">
              <v-select
                hide-details="auto"
                background-color="#ffffff"
                v-model="stakeholder.selectedType"
                :items="stakeholderTypes"
                item-text="text"
                item-value="value"
                outlined
                placeholder="Select Stakeholder Type"
                required
                :rules="rules.stakeholderType"
              >
              </v-select>
            </div>
          </div>
          <template>
            <v-card-actions class="d-flex justify-end align-center px-8 pb-10">
              <v-btn
                :loading="isInviting"
                @click="sendInvite"
                width="121"
                color="primary"
                height="45"
              >
                <simple-line-icons
                  icon="plus"
                  size="small"
                  style="color: #ffffff"
                  no-svg
                />
                <span class="pl-4 m-0 text-capitalize">Add</span>
              </v-btn>
            </v-card-actions>
          </template>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "InviteStakeholder",
  data() {
    return {
      stakeholder: {
        firstName: "",
        lastName: "",
        email: "",
        selectedType: "Co-worker",
      },
      rules: {
        first_name: [(v) => !!v || "First Name is required"],
        last_name: [(v) => !!v || "Last Name is required"],
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        stakeholderType: [(v) => !!v || "Stakeholder Type is required"],
      },
      stakeholderTypes: [
        {
          text: "Manager",
          value: "Manager",
        },
        {
          text: "Requester",
          value: "Requester",
        },
      ], // "Payee"
      isInviting: false,
      dialog: false,
    };
  },
  computed: {
    ...mapGetters({
      organizationId: "auth/organizationId",
    }),

    //create a new stakeholder username
    getUsername() {
      return this.stakeholder.firstName + this.stakeholder.lastName;
    },
  },

  methods: {
    //call toasts
    ...mapActions({ showToast: "ui/showToast" }),

    closeDialog() {
      this.dialog = false;
    },
    OpenDialog() {
      this.dialog = true;
    },
    async sendInvite() {
      if (this.$refs.form.validate()) {
        try {
          this.isInviting = true;
          const payload = {
            organization_id: this.organizationId,
            first_name: this.stakeholder.firstName,
            last_name: this.stakeholder.lastName,
            email: this.stakeholder.email,
            username: this.getUsername,
            role: this.stakeholder.selectedType,
          };
          // console.log(JSON.stringify(payload, null, 2));
          await this.$store.dispatch("organizations/inviteCoWorker", payload);
          this.showToast({
            sclass: "success",
            show: true,
            message: "co-worker successfully created",
            timeout: 3000,
          });
        } catch (err) {
          console.log(err);
          this.showToast({
            sclass: "error",
            show: true,
            message: err.msg,
            timeout: 3000,
          });
        } finally {
          this.isInviting = false;
          this.dialog = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #19283d;
}

.btn__style {
  margin-top: 24px;
  margin-bottom: 41px;
  background: #16be98;
  box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
}

.sub__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.45px;
  color: #737b7f;
}

.field__row {
  display: flex;
  justify-content: space-between;
  margin-left: 32px;
  margin-right: 32px;
  column-gap: 20px;
  margin-bottom: 20px;
}
</style>
