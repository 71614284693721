// mixins for pagination

export default {
  data() {
    return {
      currentPage: 1,
    };
  },

  methods: {
    handlePageChange(pageNumber) {
      this.currentPage = pageNumber;
      // fetch data for the new page here
    },
  },
};
