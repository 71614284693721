<template>
  <div class="auth">
    <div class="auth__wrapper">
      <div class="header">
        <a href="/">
          <img :src="require('@/assets/icons/newLogo.svg')" alt="Hyphen" />
        </a>
      </div>
      <div class="content">
        <div class="form">
          <div class="head">
            <span class="title">Create your account</span>
            <span class="steps">Step 1 of 2</span>
          </div>

          <v-text-field
            ref="invite_code"
            color="primary"
            label="Invite code"
            placeholder="Invite code"
            background-color="#ffffff"
            outlined
            class="ma-0"
            :disabled="isCreating"
            type="text"
            hide-details="auto"
            v-model="signUpDetails.invite_code"
            :rules="rules.invite_code"
          ></v-text-field>

          <div class="rows">
            <v-text-field
              ref="first_name"
              color="primary"
              placeholder="First Name"
              background-color="#ffffff"
              outlined
              class="ma-0"
              :disabled="isCreating"
              type="text"
              hide-details="auto"
              v-model="signUpDetails.first_name"
              :rules="rules.first_name"
            ></v-text-field>

            <v-text-field
              ref="last_name"
              color="primary"
              placeholder="Last Name"
              background-color="#ffffff"
              outlined
              class="ma-0"
              :disabled="isCreating"
              type="text"
              hide-details="auto"
              v-model="signUpDetails.last_name"
              :rules="rules.last_name"
            ></v-text-field>
          </div>

          <v-text-field
            ref="username"
            color="primary"
            placeholder="Username"
            background-color="#ffffff"
            outlined
            class="ma-0"
            :disabled="isCreating"
            type="text"
            hide-details="auto"
            v-model="signUpDetails.username"
            :rules="rules.username"
          ></v-text-field>

          <v-text-field
            ref="email"
            color="primary"
            placeholder="Business Email"
            background-color="#ffffff"
            outlined
            class="ma-0"
            :disabled="isCreating"
            type="email"
            hide-details="auto"
            v-model="signUpDetails.email"
            :rules="rules.email"
          ></v-text-field>

          <v-text-field
            ref="phone"
            color="primary"
            placeholder="Mobile Phone. e.g. +234080990000"
            background-color="#ffffff"
            outlined
            class="ma-0"
            :disabled="isCreating"
            type="text"
            hide-details="auto"
            v-model="signUpDetails.phone"
            :rules="rules.phone"
          ></v-text-field>

          <v-text-field
            ref="password"
            hide-details="auto"
            placeholder="Enter Your password"
            class="ma-0"
            background-color="#ffffff"
            outlined
            :disabled="isCreating"
            v-model="signUpDetails.password"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? 'text' : 'password'"
            @click:append="showPass = !showPass"
            :rules="rules.password"
          ></v-text-field>

          <v-btn
            @click="createAccount"
            block
            color="primary"
            elevation="5"
            height="54px"
            width="88px"
            class="text-capitalize action__button"
            :loading="isCreating"
          >
            Continue
          </v-btn>

          <div class="no-account">
            <span>Already have an account yet?</span>
            <a href="/login"> Sign In Here</a>
          </div>
        </div>
      </div>
    </div>
    <div class="auth__sidebar">
      <div class="cv">
        <h1>
          Automate payment processes with <br />
          Hyphen
        </h1>

        <span class="msg">
          Hyphen adapts to your internal process to help collect business
          payments faster, and control business payouts better.

          <br />
          <br />
          Start with an account in 5mins
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Register",

  data() {
    return {
      showPass: false,
      signUpDetails: {
        invite_code: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        username: "",
        password: "",
      },
      rules: {
        invite_code: [(v) => !!v || "This field is required "],
        first_name: [(v) => !!v || "This field is required "],
        last_name: [(v) => !!v || "This field is required "],
        username: [(v) => !!v || "This field is required "],
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        phone: [
          (v) => !!v || "This field is required ",
          // (v) => /^\+?[0-9]{10,14}$/.test(v) || "Number is invalid",
          // (v) =>
          //   /(^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$)/.test(v) ||
          //   "Number is invalid",
        ],
        password: [
          (v) => !!v || "This field is required",
          (v) => /(?=.*?[A-Z])/.test(v) || "At least 1 Upper Case",
          (v) => /(?=.*?[a-z])/.test(v) || "At least 1 Lower Case",
          (v) => /(?=.*?[0-9])/.test(v) || "At least 1 Number",
          (v) =>
            /(?=.*?[#?!@$%^&*-])/.test(v) ||
            "At least 1 Special Character/Symbol",
          (v) => /.{6,}/.test(v) || "At least 6 Characters",
        ],
      },

      isCreating: false,
    };
  },

  methods: {
    ...mapActions({ showToast: "ui/showToast" }),
    async createAccount() {
      Object.keys(this.form).forEach((f) => {
        //throw error to ui if there is any
        this.$refs[f].validate(true);
      });

      if (this.canCreateOrg) {
        // console.log(JSON.stringify(this.signUpDetails, null, 2));
        this.isCreating = true;
        try {
          const isValidCode = await this.checkCode();

          if (isValidCode) {
            const req = await this.$store.dispatch(
              "auth/register",
              this.signUpDetails
            );
            // console.log(JSON.stringify(req, null, 2));

            this.showToast({
              sclass: "success",
              show: true,
              message: "Account created successfully",
              timeout: 3000,
            });

            this.$router.replace(
              `/register/organization/?name=${req.user.first_name}&id=${req.user.id}`
            );
          } else {
            this.showToast({
              sclass: "error",
              show: true,
              message: "Invalid invite code",
              timeout: 4000,
            });
          }

          //now show organisation details component
        } catch (err) {
          this.showToast({
            sclass: "error",
            show: true,
            message: err.msg,
            timeout: 3000,
          });
        } finally {
          this.isCreating = false;
        }
      }
    },

    async checkCode() {
      try {
        await this.$store.dispatch(
          "auth/checkInviteCode",
          this.signUpDetails.invite_code
        );
        return true;
      } catch (err) {
        this.showToast({
          sclass: "error",
          show: true,
          message: err.msg,
          timeout: 3000,
        });
        return false;
      }
    },
  },
  computed: {
    form() {
      return {
        invite_code: this.signUpDetails.invite_code,
        first_name: this.signUpDetails.first_name,
        last_name: this.signUpDetails.last_name,
        username: this.signUpDetails.username,
        email: this.signUpDetails.email,
        phone: this.signUpDetails.phone,
        password: this.signUpDetails.password,
      };
    },

    canCreateOrg() {
      // loop through rules, if all pass user can create org
      const rules = Object.keys(this.rules);
      return rules
        .map((rule) => {
          return Object.keys(this.rules[rule])
            .map((field, index) => {
              return this.rules[rule][index](this.signUpDetails[rule]);
            })
            .every((val) => val == true);
        })
        .every((val) => val == true);
    },
  },
};
</script>

<style lang="scss" scoped>
.auth {
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  //   padding: 2rem 4rem;
  background: #f8f7f4;

  &__wrapper {
    width: 100vw;

    @media screen and (min-width: 768px) {
      width: calc(100vw - 30vw);
    }
  }

  .header {
    height: 60px;
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    width: 100vw;
    background-color: #fff;
    display: flex;
    align-items: center;

    padding: 0px 20px;

    @media screen and (min-width: 1024px) {
      background-color: unset;
      width: unset;
    }
  }

  .content {
    width: 100vw;
    height: calc(100vh - 60px);
    max-width: unset;
    padding: 20px;

    @media screen and (min-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media screen and (min-width: 1024px) {
      width: calc(100vw - 31vw);

      padding: 30px 150px;
    }

    .form {
      width: 100%;
      max-width: 450px;
      display: flex;
      flex-direction: column;
      gap: 30px;

      @media screen and (max-width: 768px) {
        padding-bottom: 30px;
      }

      .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 4px;

        // mobile view
        @media screen and (max-width: 768px) {
          flex-direction: column;
        }

        .title {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 27px;

          letter-spacing: -0.73px;

          color: #596a73;
        }

        .steps {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          /* identical to box height */

          letter-spacing: 0.484848px;

          color: #002338;

          mix-blend-mode: normal;
          opacity: 0.5;
        }
      }

      .rows {
        display: flex;
        flex-direction: row;
        gap: 20px;

        // mobile view
        @media screen and (max-width: 768px) {
          flex-direction: column;
        }
      }

      .forgot {
        display: flex;
        justify-content: flex-end;
        padding-top: 5px;

        a {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0.278409px;
          color: #301f78;
          mix-blend-mode: normal;
          opacity: 0.5;
          text-decoration: none;
          cursor: pointer;
        }
      }

      .no-account {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;

        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.278409px;
          color: #7f919b;
        }

        a {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.278409px;
          color: #5b67ba;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }

  &__sidebar {
    display: none;
    width: 30vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: fixed;
    right: 0;
    top: 0;
    background-color: #fff;

    .cv {
      width: 90%;
      max-width: 400px;
    }

    h1 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 46px;
      color: #19283d;
    }

    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: rgba(25, 40, 61, 0.8);
    }

    @media screen and (min-width: 1024px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
