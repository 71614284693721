import api from "./Api";

export default {
  //get all receivables for an organization
  getAllReceivables(organization_hypnID) {
    return api().get(`transactions/receivables/${organization_hypnID}`, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  //get a single receivable of an organisation using the organization_hypnID and receivable ID
  getReceivable(organization_hypnID, receivableID) {
    return api().get(
      `transactions/receivables/${organization_hypnID}/${receivableID}`,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    );
  },

  //get receievables balance for an organization
  getReceivablesBalance(organization_hypnID) {
    return api().get(
      `transactions/receivables/balance/${organization_hypnID}`,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    );
  },
};
