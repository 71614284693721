export default () => ({
  toast: {
    sclass: "",
    message: "",
    // color: "",
    show: false,
    timeout: -1,
    icons: {
      success: "mdi-check",
      warning: "mdi-alert",
      error: "mdi-window-close",
      info: "mdi-information",
    },
    icon: "",
  },
});
