import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import appMixin from "./plugins/app-mixin";
import DateFilter from "./filters/date";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import { ValidationObserver } from "vee-validate";
import { VueFormBuilderPlugin } from "@araromi/v-form-builder";
import JsonCSV from "vue-json-csv";
import VueSkeletonLoader from "skeleton-loader-vue";
import Clipboard from "v-clipboard";
import VueCurrencyFilter from "vue-currency-filter";
import SimpleLineIcons from "vue-simple-line";
import VueApexCharts from "vue-apexcharts";

import "./plugins/flagsmith";
import "./plugins/vuetify-mask.js";

//import mono
import Mono from "vue-mono";

Vue.config.productionTip = false;

//imported components
Vue.component("simple-line-icons", SimpleLineIcons);

//importing the vue-validate globally
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

//global component for apex charts
Vue.component("apexchart", VueApexCharts);
// register jw pagination component globally
import JwPagination from "jw-vue-pagination";

Vue.component("jw-pagination", JwPagination);

// export json to csv
Vue.component("downloadCsv", JsonCSV);

// Register the vue-skeleton-loader
Vue.component("vue-skeleton-loader", VueSkeletonLoader);

// install now

Vue.use(VueFormBuilderPlugin);
Vue.use(Clipboard);
Vue.use(VueApexCharts);
Vue.use(VueCurrencyFilter, {
  symbol: "",
  thousandsSeparator: ",",
  fractionCount: 2,
  fractionSeparator: ".",
  symbolPosition: "front",
  symbolSpacing: true,
  avoidEmptyDecimals: undefined,
});
//Vue.use(Mono, { publicKey: process.env.VUE_APP_MONOKEY });
// Vue.use(Mono, { publicKey: process.env.VUE_APP_MONOKEY });
Vue.use(Mono, { publicKey: process.env.VUE_APP_MONOKEY });

//register date filter
Vue.filter("date", DateFilter);
Vue.use(require("vue-moment"));

Vue.mixin(appMixin);

new Vue({
  router,
  store,
  vuetify,

  render: (h) => h(App),
}).$mount("#app");
