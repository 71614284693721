import MainLayout from "@/layouts/MainLayout.vue";

const routes = [
  {
    path: "/workflow",
    name: "Workflow",
    components: {
      default: () => import("@/pages/workflow/index.vue"),
      MainLayout,
    },
    meta: { middleware: ["auth", "tools"] },
  },
  {
    path: "/workflow/new",
    name: "NewWorkFlow",
    components: {
      default: () => import("@/pages/workflow/new.vue"),
      MainLayout,
    },
    meta: { middleware: ["auth", "tools"] },
  },
  {
    path: "/workflow/:id",
    name: "EditWorkflow",
    components: {
      default: () => import("@/pages/workflow/view.vue"),
      MainLayout,
    },
    meta: { middleware: ["auth", "tools"] },
  },
  {
    path: "/workflow-sdk",
    name: "WorkflowSDK",
    components: {
      default: () => import("@/workflow-sdk/sdk.vue"),
    },
  },
  {
    path: "/workflow-sdk-demo",
    name: "WorkflowSDK",
    components: {
      default: () => import("@/workflow-sdk/sdk.vue"),
    },
    meta: { middleware: ["auth", "tools"] },
  },
  {
    path: "/demo",
    name: "WorkflowSDKDemo",
    components: {
      default: () => import("@/workflow-sdk/demo.vue"),
    },
    meta: { middleware: ["auth", "tools"] },
  },
];

export default routes;
