<template>
  <div v-if="approval">
    <v-navigation-drawer
      width="400px"
      v-model="approvalDetailsNav"
      temporary
      right
      app
    >
      <SingleEntriesDetails v-if="showEntry" :entryId="singleEntryId" />

      <div v-else class="approval-details">
        <div class="approval-details__header">
          <span>Approval</span>
          <v-btn color="#596A73" icon @click="approvalDetailsNav = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>

        <div class="approval-details__body">
          <div class="info-cover">
            <div class="data">
              <span>workflow ID</span>
              <span style="font-size: 18px">{{ approval.title || "N/A" }}</span>
            </div>
            <div class="data">
              <span>requester</span>
              <span>{{ approval.requester || "N/A" }}</span>
            </div>

            <div class="data">
              <span>Description</span>
              <span>{{ approval.description || "N/A" }}</span>
            </div>

            <div class="data">
              <span>Line Items</span>
              <div class="lines">
                <div
                  class="lines__item"
                  :class="{
                    clickable: item.key === 'entry',
                    alignCenter: item.key === 'entry',
                  }"
                  v-for="(item, index) in items"
                  :key="index"
                  @click="
                    item.key === 'entry' ? showEntryDetails(item.value) : null
                  "
                >
                  <span>{{ camelToSpace(item.key) }}</span>
                  <span v-if="item.key === 'entry'">
                    <v-chip
                      color="#EEFCF9"
                      text-color="#2BD5AE"
                      class="chip__text"
                      >{{ item.value }}</v-chip
                    >
                  </span>
                  <span v-else>{{ item.value }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="comment">
            <template v-if="comments && comments.length > 0">
              <div class="view-comments">
                <img :src="require('@/assets/approval-comments.svg')" alt="" />
                <button @click="showComments = !showComments">
                  {{ showComments ? "hide" : "view" }} comments
                </button>
              </div>

              <div class="titleh" :class="{ show: showComments }">
                Below is a timeline of approvals for this transaction
              </div>

              <div class="timeline" :class="{ show: showComments }">
                <span v-for="(comment, index) in comments" :key="index">
                  <div class="timeline__data">
                    <div class="head">
                      <span class="approver">{{
                        comment.approver.username
                      }}</span>
                      <span class="status"
                        >{{ comment.is_approved ? "Approved" : "Denied" }}
                        <img
                          :src="
                            require(`@/assets/approval-${
                              comment.is_approved ? 'approved' : 'denied'
                            }.svg`)
                          "
                        />
                      </span>
                    </div>

                    <div class="quote">
                      {{ comment.comment }}
                    </div>

                    <div class="date">{{ comment.created_at | date }}</div>
                  </div>
                  <div
                    v-if="index !== comments.length - 1"
                    class="timeline__divider"
                  ></div>
                </span>
              </div>
            </template>
          </div>
        </div>

        <div
          class="approval-details__actions"
          v-if="approval && !approval.is_approved"
        >
          <v-btn
            style="background-color: #f4f5f6; flex: 1"
            large
            elevation="0"
            :loading="actingApprv"
            @click="approveAction(0)"
          >
            <v-icon left>mdi-chevron-right</v-icon> Deny</v-btn
          >
          <v-btn
            @click="approveAction(1)"
            color="primary"
            style="flex: 1"
            large
            :loading="actingApprv"
            elevation="0"
            left
          >
            <v-icon left>mdi-chevron-right</v-icon> Approve</v-btn
          >
        </div>
      </div>
    </v-navigation-drawer>

    <v-dialog
      v-model="newCommentDialog"
      :persistent="actingApprv"
      max-width="650px"
      width="100%"
      transition="dialog-transition"
    >
      <div class="action">
        <div class="action__header">
          <div class="b">
            <img
              class="i"
              :src="require('@/assets/actions-get-approval.svg')"
            />
            <span class="t">{{ approveAct === 0 ? "Deny" : "Approve" }}</span>
          </div>
          <v-btn @click="close" icon color="primary">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="action__content" :style="'background-color:#f8f7f4'">
          <div class="top">
            <span class="action-title">New Comment</span>
          </div>
          <!-- <span class="action-description"
          >Select the co-worker from who approval is required for this
          stage</span
        > -->

          <div class="a-wrapper">
            <v-textarea
              outlined
              textarea
              color="primary"
              v-model="comment"
              hide-details="auto"
              placeholder="No comment"
            ></v-textarea>
          </div>

          <div class="bottom">
            <v-btn @click="close" large color="primary" outlined>
              <v-icon left>mdi-close</v-icon> Cancel
            </v-btn>

            <v-btn
              @click="submit"
              large
              color="primary"
              :loading="actingApprv"
              elevation="0"
            >
              <v-icon left>mdi-chevron-right</v-icon> Confirm
            </v-btn>
          </div>
        </div>
      </div>
    </v-dialog>

    <v-dialog
      v-model="approvalResponseDialog"
      max-width="400px"
      transition="dialog-transition"
    >
      <v-card>
        <div class="feedback">
          <button class="close-btn" @click="approvalResponseDialog = false">
            <v-icon>mdi-close</v-icon>
          </button>
          <div>
            <span
              class="iconx"
              :class="{ approve: approveAct === 1, deny: approveAct === 0 }"
              ><v-icon size="40" v-if="approveAct === 1" color="#2bd4ae"
                >mdi-check</v-icon
              >
              <v-icon size="40" v-else color="#ff6a6a">mdi-close</v-icon>
            </span>

            <span class="message"
              >Thank you, your selected action <br /><b
                >- {{ approveAct === 1 ? "APPROVE" : "DENY" }} -</b
              >
              <br />
              has been confirmed.</span
            >
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import SingleEntriesDetails from "@/pages/forms/singleEntriesDetails.vue";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    approval: {
      type: Object,
      default: () => null,
    },

    items: {
      type: Array,
      default: () => [],
    },

    email: {
      type: String,
      default: "",
    },
  },
  components: {
    SingleEntriesDetails,
  },
  data() {
    return {
      step: 1,
      otp: null,
      showEntry: false,
      singleEntryId: null,
      actingApprv: false,
      optData: null,
      approvalMsg: ``,
      isVeryfying: false,
      approvalUrl: null,
      approvalDetailsNav: false,
      newCommentDialog: false,
      showComments: false,
      approvalResponseDialog: false,
      comment: "",
      comments: [],
      approveAct: null,
      commentId: null,
    };
  },

  methods: {
    ...mapActions({ showToast: "ui/showToast" }),

    async close() {
      if (!this.actingApprv) {
        this.newCommentDialog = false;
        this.approveAct = null;

        await new Promise((resolve) => setTimeout(resolve, 500));
        this.$emit("input", true);
      }
    },

    approveAction(approveAct) {
      this.approveAct = approveAct;

      this.newCommentDialog = true;
    },

    showEntryDetails(id) {
      console.log(id);
      this.singleEntryId = id;
      this.showEntry = true;
    },

    async getComents() {
      try {
        const { data } = await this.$store.dispatch(
          "approvals/getApprovalComment",
          this.approval.hypn_id
        );

        this.comments = data.data;
      } catch (err) {
        this.showToast({
          sclass: "error",
          show: true,
          message: err.msg || "An error occurred",
          timeout: 3000,
        });
      }
    },

    async submit() {
      try {
        this.actingApprv = true;
        await this.$store.dispatch("approvals/addApprovalComment", {
          actionId: this.approval.action_id,
          data: {
            action: this.approveAct,
            comment: this.comment,
            contact: this.email,
          },
        });
        this.approvalResponseDialog = true;
        this.newCommentDialog = false;
      } catch (error) {
        this.showToast({
          sclass: "error",
          show: true,
          message: error.msg || "An error occurred",
          timeout: 3000,
        });
      } finally {
        this.actingApprv = false;
      }
    },

    camelToSpace(str) {
      return (
        str
          // Replace underscores with spaces
          .replace(/_/g, " ")
          // Insert a space before all caps
          .replace(/([A-Z])/g, " $1")
          // Uppercase the first character
          .replace(/^./, (str) => {
            return str.toUpperCase();
          })
      );
    },
  },

  watch: {
    value: {
      handler(val) {
        if (val !== this.approvalDetailsNav) {
          this.approvalDetailsNav = val;
        }

        // body overflow hidden
        if (val) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "auto";
        }
      },
      immediate: true,
    },
    approvalDetailsNav(val) {
      this.$emit("input", val);

      if (val && this.comments.length === 0) {
        this.getComents();
      }

      if (!val) {
        this.singleEntryId = null;
        this.showEntry = false;
      }
    },

    newCommentDialog(val) {
      if (!val) {
        this.comment = "";
      }
    },

    approvalResponseDialog(val) {
      if (!val) {
        this.approveAct = null;
        this.comment = "";
        this.$emit("refresh");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.approval-details {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100vh;

  .chip__text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 10px;
    padding: 10px;
    cursor: pointer;
  }

  @media (min-width: 768px) {
    padding: 20px 30px;
  }

  .clickable {
    cursor: pointer;
  }

  .alignCenter {
    align-items: center;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: #19283d;
    }
  }

  &__body {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--v-primary-base);
      border-radius: 10px;
    }

    .info-cover {
      padding: 0px 5px;
      .data {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 0 0px;
        gap: 7px;
        margin-bottom: 20px;

        span:first-child {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: #8f96a1;
        }

        span:last-child {
          font-weight: 700;
          font-size: 17px;
          line-height: 22px;
          color: #19283d;
        }

        .lines {
          width: 100%;
          &__item {
            display: flex;
            width: 100%;
            // white-space: nowrap;
            // align-items: center;
            padding: 10px 0;
            gap: 10px;
            border-bottom: 1px dashed #d9dee1;

            span:first-child {
              font-weight: 700;
              font-size: 15px;
              line-height: 22px;
              color: #19283d;
              flex: 1;
              word-break: break-all;
            }

            span:last-child {
              font-weight: 400;
              font-size: 16px;
              flex: 1.5;

              color: rgba(25, 40, 61, 0.8);
            }
          }
        }
      }
    }

    .comment {
      height: auto;

      //1300px and above

      margin: 5px 0;
      &::-webkit-scrollbar {
        width: 8px;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--v-primary-base);
        border-radius: 10px;
      }
      .view-comments {
        text-align: left;
        margin: 20px 0;
        color: #5b67ba;
        display: flex;
        align-items: center;
        gap: 7px;

        img {
          width: 20px;
          height: 20px;
        }

        button {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          text-decoration: underline;
        }
      }
      .titleh {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        color: #525e6e;
        display: none;

        &.show {
          display: block;
        }
      }

      .timeline {
        flex: 1;
        overflow-y: auto;

        height: 0px;
        transition: all 0.3s ease-in-out;

        &.show {
          height: auto;
          padding: 30px 0px;
        }

        &__divider {
          margin: auto;
          height: 35px;
          width: 1px;
          border-right: 2px dashed #8f96a1;
        }

        &__data {
          box-sizing: border-box;
          padding: 15px;

          background: #ffffff;
          /* line */

          border: 1px solid #d9dee1;
          border-radius: 4px;

          .head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            gap: 10px;

            .approver {
              font-family: "Inter";
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 28px;
              color: #19283d;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .status {
              font-family: "Inter";
              font-style: italic;
              font-weight: 500;
              font-size: 13px;
              line-height: 28px;
              color: #8f96a1;
              display: inline-flex;
              align-items: center;
              gap: 5px;
            }
          }

          .quote {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 15;
            line-height: 24px;
            /* or 200% */

            /* menu txt */

            color: #525e6e;
            margin: 10px 0px;
          }

          .date {
            text-align: right;
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: rgba(25, 40, 61, 0.8);
          }
        }
      }
    }
  }

  &__actions {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
}

.action {
  border-radius: 8px;
  background-color: #fff;
  &__header {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // mobile view
    @media (max-width: 600px) {
      padding: 15px;
    }
    .b {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .i {
      width: 26px;
      height: 26px;
      object-fit: fit;

      @media (max-width: 600px) {
        width: 20px;
        height: 20px;
      }
    }
    .t {
      color: var(--v-primary-base);
      font-weight: 600;
      font-size: 20px;

      @media (max-width: 600px) {
        font-size: 16px;
      }
    }
  }
  &__content {
    background-color: #f8f7f4;
    padding: 20px 50px;

    @media (max-width: 600px) {
      padding: 20px 20px;
    }
    .top {
      padding: 30px 0px 0px 0px;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);

      @media (max-width: 600px) {
        padding: 10px 0px 0px 0px;
      }
      .action-title {
        color: var(--v-primary-base);
        text-transform: uppercase;
        font-weight: 600;
        border-bottom: 5px solid var(--v-primary-base);
        font-size: 16px;
        height: 40px;
        padding: 0px 15px;
        display: inline-block;

        // mobile view
        @media (max-width: 600px) {
          font-size: 14px;
          font-weight: 500;
          border-bottom: 3px solid var(--v-primary-base);
          height: 35px;
          padding: 0px 10px;
        }
      }
    }
    .action-description {
      color: var(--v-primary-base);
      display: block;
      padding: 20px 0px;
      font-size: 16px;
    }
    .a-wrapper {
      background-color: #fff;
      margin: 50px 20px;
      border-radius: 4px;

      // mobile view
      @media (max-width: 600px) {
        margin: 20px 0px;
        padding: 0px;
      }
    }
    .note {
      display: block;
      margin-top: 10px;
      color: #8f96a1;
    }
    .bottom {
      display: flex;
      justify-content: flex-end;
      margin: 20px 20px;
      gap: 20px;

      // mobile view
      @media (max-width: 600px) {
        margin: 20px 0px;
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}

.feedback {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  justify-content: center;
  padding: 20px;
  height: 400px;
  border-radius: 8px;
  position: relative;

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .iconx {
    width: 80px;
    height: 80px;
    display: flex;
    margin: 0px auto 30px auto;
    justify-content: center;
    align-items: center;

    border-radius: 50%;

    &.approve {
      border: 2px solid #2bd4ae;
    }

    &.deny {
      border: 2px solid #ff6a6a;
    }
  }

  .message {
    max-width: 300px;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 500;
    font-family: "Inter";
  }
}
</style>
