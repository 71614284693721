import approvals from "../../api/approvals";

const state = {
  allApprovals: null,
  comment: null,
  comments: [],
};
const getters = {
  getAllApprovals: (state) => state.allApprovals,
  //get pagination data
  getPagination(state) {
    if (state.allApprovals.pagination != null) {
      return state.allApprovals.pagination;
    }
  },
  numOfApprovals(state) {
    if (state.allApprovals != null) {
      return state.allApprovals.pagination
        ? state.allApprovals.pagination.total
        : 0;
    }
  },
};
const mutations = {
  SET_APPROVALS(state, data) {
    state.allApprovals = data;
    // console.log(JSON.stringify(data, null, 2));
  },

  SET_COMMENT(state, data) {
    state.comment = data;
  },

  SET_COMMENTS(state, data) {
    state.comments = data;
  },
};
const actions = {
  async addApprovalComment({ commit }, payload) {
    try {
      const response = await approvals.addApprovalComment(payload);
      commit("SET_COMMENT", response.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // getApprovalComment
  async getApprovalComment({ commit }, hyphenId) {
    try {
      const response = await approvals.getApprovalComment(hyphenId);
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(response.data.data, null, 2));
      }
      commit("SET_COMMENT", response.data.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  //get all approvals for an organization
  async getAllApprovals({ commit }, orgId) {
    try {
      const response = await approvals.getAllApprovals(orgId);
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(response.data.data, null, 2));
      }
      commit("SET_APPROVALS", response.data.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
