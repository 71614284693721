import flagsmith from "flagsmith";
import store from "../store";

let features = [];
flagsmith.init({
  environmentID: process.env.VUE_APP_FLAGSMITH_ENVIRONMENT_ID,
  cacheFlags: true,
  defaultFlags: {
    tools: { enabled: true },
    procurement: { enabled: true },
    inbox: { enabled: true },
    forms: { enabled: true },
    workflows: { enabled: true },
    intelligence: { enabled: true },
    contacts: { enabled: true },
    payaybles: { enabled: true },
    receivables: { enabled: true },
    payment: { enabled: true },
    transactions: { enabled: true },
    reconcillation: { enabled: true },
    approvals: { enabled: true },
    statements: { enabled: true },
    team: { enabled: true },
  },
  onChange: () => {
    const flags = flagsmith.getAllFlags();
    features = Object.keys(flags).map((key) => {
      return { key, value: flags[key].enabled };
    });

    store.commit("flagsmith/setFlags", features);
  },
});
