import api from "./Api";
export default {
  addApprovalComment(payload) {
    return api().post(`approvals/app/${payload.actionId}`, payload.data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },

  getApprovalComment(hyphn_id) {
    return api().get(`workflows/comments/${hyphn_id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },

  //get all payables for an organization

  getAllApprovals(organization_hypnID) {
    return api().get(`approvals/organization/${organization_hypnID}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },

  // getAllApprovals() {
  //   return api().get(
  //     `https://run.mocky.io/v3/1e48826a-f1fa-4e44-8a47-85572c959110`
  //   );
  // },
};
