import formbuilder from "../../api/formbuilder";

const state = {
  formCards: null,
  singleForm: null,
  is_payment: false,
  has_signature: false,
  formDetails: {},
  paymentDetails: null,
  entriesResponse: null,
  entries: null,
  entriesCount: null,
  selectedFormType: {},
  api_url: "",
  variableAmount: true,
  payment: true,
  formItems: [
    {
      name: "",
      amount: "",
    },
  ],
  singleEntry: null,
};

const getters = {
  forms: (state) => {
    let forms = [];
    if (state.formCards != null) {
      forms = state.formCards;
      // console.log(forms);
    }
    //sort array by created_at
    forms.sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    });

    return forms;
  },
  getSingleForm: (state) => {
    if (state.singleForm != null) {
      return state.singleForm;
    }
  },
  getPaymentStatus: (state) => {
    if (state.is_payment != null) {
      return state.is_payment;
    }
  },

  getSignatureStatus: (state) => {
    if (state.has_signature != null) {
      return state.has_signature;
    }
  },
  getFormDetails: (state) => {
    if (state.formDetails != {}) {
      return state.formDetails;
    }
  },
  getPaymentDetails: (state) => {
    if (state.paymentDetails != {}) {
      return state.paymentDetails;
    }
  },
  getApiObject: (state) => {
    if (state.API != "") {
      return state.API;
    }
  },
  getSelectedFormType: (state) => {
    if (state.getSelectedFormType != "") {
      return state.selectedFormType;
    }
  },
  entriesResponse: (state) => {
    if (state.entriesResponse != null) {
      return state.entriesResponse;
    }
  },
  entriesCount: (state) => {
    if (state.entriesCount != null) {
      // console.log(JSON.stringify(state.entriesCount.data, null, 2));
      return state.entriesCount.data;
    }
  },

  getFormItemsArray: (state) => {
    return state.formItems;
  },
  getApiUrl: (state) => {
    return state.api_url;
  },
  getVariableAmount: (state) => {
    return state.variableAmount;
  },
  TakePaymentStatus: (state) => {
    return state.payment;
  },

  entriesDetails: (state) => {
    return state.singleEntry;
  },
};

const mutations = {
  commitForm(state, form) {
    state.formCards = form;
  },
  setForms(state, forms) {
    state.formCards = forms;
  },
  singleForm(state, form) {
    state.singleForm = form;
    //console.log(JSON.stringify(form, null, 2));
  },

  async updateForm(state, form) {
    const index = state.formCards.findIndex((item) => item.id === form.id);
    if (index !== -1) {
      state.formCards[index] = form;
    }

    state.formCards = [...state.formCards];
  },
  deleteForm(state, formId) {
    let index = state.formCards.findIndex((form) => form.id == formId);
    if (index >= 0) {
      state.formCards.splice(index, 1);
    }
  },

  GETFORMENTRIES(state, entriesResponse) {
    state.dataEntries = entriesResponse;
  },
  SET_MAKEPAYMENT(state, value) {
    // console.log(value);
    state.is_payment = value;
  },
  SET_FORMTYPE(state, value) {
    // console.log(value);
    state.selectedFormType = value;
  },
  SET_API(state, value) {
    // console.log(value);
    state.API = value;
  },
  SET_SIGNATURE(state, value) {
    //console.log(value);
    state.has_signature = value;
  },
  SET_FORM_DETAILS(state, data) {
    // console.log(data);
    state.formDetails = data;
  },
  SET_PAYMENT_DETAILS(state, data) {
    // console.log(data);
    state.paymentDetails = data;
  },
  SET_ENTRIES_COUNT(state, data) {
    // console.log(JSON.stringify(data, null, 2));
    state.entriesCount = data;
  },
  ADD_FORMITEM(state, data) {
    state.formItems.push(data);
  },
  REMOVE_FORMITEM(state, index) {
    if (state.formItems.length > 1) {
      state.formItems.splice(index, 1);
    }
  },
  UPDATE_VARIABLEAMOUT(state, data) {
    state.variableAmount = data;
  },
  UPDATE_API_URL(state, data) {
    state.api_url = data;
  },
  UPDATE_TAKEPAYMENT(state, data) {
    state.payment = data;
  },

  UPDATE_FORM_ARRAY(state, data) {
    state.formItems = data;
  },
  SET_SINGLE_ENTRY(state, data) {
    state.singleEntry = data;
  },
};

const actions = {
  addNewFormRow({ commit }, data) {
    commit("ADD_FORMITEM", data);
  },
  removeFieldRow({ commit }, index) {
    commit("REMOVE_FORMITEM", index);
  },

  enableVariable({ commit }, data) {
    commit("UPDATE_VARIABLEAMOUT", data);
  },
  enablePayment({ commit }, data) {
    commit("UPDATE_TAKEPAYMENT", data);
  },
  updateApiURL({ commit }, data) {
    commit("UPDATE_API_URL", data);
    //console.log(status);
  },

  updateFormItems({ commit }, data) {
    commit("UPDATE_FORM_ARRAY", data);
  },
  updatePaymentStatus({ commit }, status) {
    commit("SET_MAKEPAYMENT", status);
    //console.log(status);
  },
  updateSignatureStatus({ commit }, status) {
    commit("SET_SIGNATURE", status);
    //console.log(status);
  },

  updateFormStoreData({ commit }, data) {
    commit("updateForm", data);
    console.log(data);
  },
  updateSingleFormStoreData({ commit }, data) {
    commit("singleForm", data);
    console.log(data);
  },
  getApiObject({ commit }, status) {
    commit("SET_API", status);
    //  console.log(status);
  },
  saveFormDetailsToStore({ commit }, data) {
    commit("SET_FORM_DETAILS", data);
    // console.log(data);
  },
  savePaymentDetailsToStore({ commit }, data) {
    commit("SET_PAYMENT_DETAILS", data);
    // console.log(data);
  },

  async createForm({ commit }, form) {
    try {
      const response = await formbuilder.create(form);
      if (process.env.NODE_ENV === "development") {
        console.log(
          "newly created form",
          JSON.stringify(response.data, null, 2)
        );
      }
      commit("commitForm", response.data);
      return response;
    } catch (error) {
      //console.log(JSON.stringify(error, null, 2));
      return Promise.reject(error);
    }
  },

  async FetchAllForms({ commit }) {
    try {
      const response = await formbuilder.getAllForms();
      commit("setForms", response.data);
      // console.log(JSON.stringify(response.data, null, 2));
      return response;
    } catch (error) {
      // console.log(JSON.stringify(error, null, 2));
      return Promise.reject(error);
    }
  },
  async getSingleForm({ commit }, id) {
    try {
      const response = await formbuilder.getForm(id);
      commit("singleForm", response.data);
      return response;
    } catch (error) {
      //console.log(JSON.stringify(error, null, 2));
      return Promise.reject(error);
    }
  },

  async updateForm({ commit }, data) {
    try {
      const response = await formbuilder.updateForm(data.id, data.payload);
      commit("updateForm", response.data);
      //console.log(JSON.stringify(response.data, null, 2));
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async getFormEntries({ commit }, hypn_id) {
    try {
      const response = await formbuilder.getFormEntries(hypn_id);
      commit("GETFORMENTRIES", response.data);
      //console.log(response.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async deleteForm({ commit }, id) {
    try {
      const response = await formbuilder.deleteForm(id);

      commit("deleteForm", id);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async getFormEntriesCount({ commit, rootState }) {
    try {
      const response = await formbuilder.getFormEntrieCount(
        rootState.auth.user.organization
      );
      commit("SET_ENTRIES_COUNT", response.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSingleFormEntry({ commit }, entryId) {
    try {
      const response = await formbuilder.getSingleEntriesId(entryId);
      commit("SET_SINGLE_ENTRY", response.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
